import React from 'react'
import { useReactToPrint } from "react-to-print";
import "./Letter.css"
import kaabilLogo from "../../../assets/kaabil.png"
import datehelper from '../../../_helpers/datehelper';

const ForClosureLetter = (props) => {

    const ForClosureLetterRef = React.useRef();

    const handleSubmissionPrint = useReactToPrint({
        content: () => ForClosureLetterRef.current,
        pageStyle: "print"
    });

    const data = props.location.state;
    
    let total = Number(data.ledger_balance) + Number(data.lpi) + Number(data.interest) + Number(data.document_charges) + Number(data.bounce_charges) + Number(data.foreclosure_charges) + Number(data.gst); 

    let date = new Date();
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct",
        "Nov", "Dec"];
    const yy = date.getFullYear();
    const mm = date.getMonth();
    const dd = date.getDate();

    date = months[mm] + " " + dd + "," + " " + yy;

    return (
        <>

            <div style={{ textAlign: "center" }}>
                <button onClick={handleSubmissionPrint}>Print Letter</button>
            </div>


            <div ref={ForClosureLetterRef}  >
            <img
            src={kaabilLogo}
            style={{ height: "150px", marginLeft: "30%" }}
            alt="KFPL Logo"
          />
                <div style={{ marginTop: "80px", textAlign: "justify", marginLeft: "53px", fontSize:"18px" }}>
            
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <span>Date: { datehelper.displayDate(new Date(data.createdAt)) }</span>
                    <span>Letter Id: {data.letter_id}</span>
                </div><br />

                <div>
                    <b>{data.name}</b><br />
                    <b>S/o {data.sonof}</b><br />
                    Address:- {data.address}<br />
                    Contact No: {data.contact}<br />
                </div><br /><br />

                <div style={{ textAlign: "center" }}>
                    <b>Sub: Foreclosure letter for Loan Account No- {data.application_id}</b>
                </div><br />

                <div>Dear Sir/Madam,</div><br />

                <div>
                    With reference to the above-mentioned loan and as requested given below are the foreclosure figures for
                    the same as on { datehelper.displayDate(new Date(data.createdAt)) }.
                </div><br />

                <div>
                    <table style={{ width: "60%", border: "1px solid black", margin: "auto" }}>
                        <tbody>
                            {data.ledger_balance === "" ? null :
                                <tr>
                                    <td style={{ border: "1px solid black", height:"20px" }}>
                                        Ledger Balance
                                    </td>
                                    <td style={{ border: "1px solid black", height:"20px" }}>
                                        {data.ledger_balance.toLocaleString("en-IN", {
                         maximumFractionDigits: 2,
                        style: "currency",
                        currency: "INR",
                      })}
                                    </td>
                                </tr>
                            }
                            {data.lpi === "" ? null :
                                <tr>
                                    <td style={{ border: "1px solid black" }}>
                                    LPI
                                    </td>
                                    <td style={{ border: "1px solid black" }}>
                                        {data.lpi.toLocaleString("en-IN", {
                         maximumFractionDigits: 2,
                        style: "currency",
                        currency: "INR",
                      })}
                                    </td>
                                </tr>
                            }
                            {data.interest === "" ? null :
                                <tr>
                                    <td style={{ border: "1px solid black" }}>
                                        Interest
                                    </td>
                                    <td style={{ border: "1px solid black" }}>
                                        {data.interest.toLocaleString("en-IN", {
                         maximumFractionDigits: 2,
                        style: "currency",
                        currency: "INR",
                      })}
                                    </td>
                                </tr>
                            }
                            {data.document_charges === "" ? null :
                                <tr>
                                    <td style={{ border: "1px solid black" }}>
                                    Documents Ret.Charges
                                    </td>
                                    <td style={{ border: "1px solid black" }}>
                                        {data.document_charges.toLocaleString("en-IN", {
                         maximumFractionDigits: 2,
                        style: "currency",
                        currency: "INR",
                      })}
                                    </td>
                                </tr>
                            }
                            {data.bounce_charges === "" ? null :
                                <tr>
                                    <td style={{ border: "1px solid black" }}>
                                    Bounce Charges
                                    </td>
                                    <td style={{ border: "1px solid black" }}>
                                        {data.bounce_charges.toLocaleString("en-IN", {
                         maximumFractionDigits: 2,
                        style: "currency",
                        currency: "INR",
                      })}
                                    </td>
                                </tr>
                            }
                            {data.foreclosure_charges === "" ? null :
                                <tr>
                                    <td style={{ border: "1px solid black" }}>
                                    Foreclosure Charges
                                    </td>
                                    <td style={{ border: "1px solid black" }}>
                                        {data.foreclosure_charges.toLocaleString("en-IN", {
                         maximumFractionDigits: 2,
                        style: "currency",
                        currency: "INR",
                      })}
                                    </td>
                                </tr>
                            }
                            {data.gst === "" ? null :
                                <tr>
                                    <td style={{ border: "1px solid black" }}>
                                     GST
                                    </td>
                                    <td style={{ border: "1px solid black" }}>
                                        {data.gst.toLocaleString("en-IN", {
                         maximumFractionDigits: 2,
                        style: "currency",
                        currency: "INR",
                      })}
                                    </td>
                                </tr>
                            }
                           
                                <tr>
                                    <td style={{ border: "1px solid black" }}>
                                    Total
                                    </td>
                                    <td style={{ border: "1px solid black" }}>
                                    {total.toLocaleString("en-IN", {
                         maximumFractionDigits: 2,
                        style: "currency",
                        currency: "INR",
                      })}
                                    </td>
                                </tr>
                            
                            {data && data.item && data.item !== "" && data.item.itemKey !== "" && data.item.map((e) => (
                                <tr>
                                    <td style={{ border: "1px solid black" }}>{e.itemKey}</td>
                                    <td style={{ border: "1px solid black" }}>{e.itemValue}</td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div><br />

                <div>
                    Please Note Interest of Rs. {data.daily_basis_interest.toLocaleString("en-IN", {
                         maximumFractionDigits: 2,
                        style: "currency",
                        currency: "INR",
                      })}/- accrues on a daily basis till the date of receipt of full and final payment.
                    DD/Cheque/NEFT/RTGS/IMPS should be in favoring <b>"Kaabil Finance Private Limited "</b>. Once the total
                    outstanding amount is received in our bank account and settled there is TAT of 30 working days for
                    dispatch/handover of Property papers, No Dues letter and refund cheques if any. This letter is valid for 90
                    days from the date of issuance.
                </div><br />

                <div>
                    Should you require any other information/ assistance please call us on our Head office number 0141-4005012 or contact nearby branch head.
                </div><br />

                <div>
                    We look forward to your continued patronage and assure you of our best services at all times.
                </div><br />

                <div>
                    Your's Faithfully
                </div><br />
                {/* <img src={signature} alt="Signature" style={{height: "130px", marginLeft: "0"}} /> */}
                <div>
                    Authorized Signatory,
                </div>
            </div>
            </div>
        </>
    )
}

export default ForClosureLetter