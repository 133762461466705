import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Fab from "@material-ui/core/Fab";
import SaveIcon from "@material-ui/icons/Save";
import { Button,Select } from "@material-ui/core";
import { employeeService, misService } from "../../_services";
import FormElement from "../uielements/FormElement";
import { reportsService } from "../../_services/reports.service";
import roleChecker from "../../_helpers/rolechecker";
import datehelper from "../../_helpers/datehelper";

const useStyles = makeStyles((theme) => ({
  modal: {
    width: "50%",
    overflow: "scroll",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  table: {
    minWidth: 650,
  },
}));

export default function ReportRequestModal(props) {
  const loan = props.loan;
  const classes = useStyles();
  const [isDateInput,setIsDateInput]= React.useState(false);
  const [isDateRangeInput,setIsDateRangeInput]= React.useState(false);
  const [isFirstDateRangeInput,setIsFirstDateRangeInput]= React.useState(false);
  const [isMonthInput,setIsMonthInput]= React.useState(false);
  const [isYearInput,setIsYearInput]= React.useState(false);
  
  
  
  const [open, setOpen] = React.useState(false);
  const [reportTypes,setReportTypes]=React.useState([])
  const formikRef = React.useRef();
  const user = employeeService.userValue;
  console.log('user',user)
  const monthBack=datehelper.getPastDate("Y-m-d",30);
  const maxDate= datehelper.getMaxPastDate("Y-m-d")
  const todayDate= new Date()
    
  const currentMonth=(todayDate.getMonth()+1)+"-"+todayDate.getFullYear()
  console.log('todayDate,currentMonth',todayDate,currentMonth)
  let currentFYYear=todayDate.getFullYear()
  if(todayDate.getMonth()>3){
    currentFYYear=currentFYYear+1
  }
  const initialValues = {
    business_div: "",
    report_type:"",
    input_date:maxDate,
    start_date:monthBack,
    end_date:maxDate,
    month:currentMonth,
    year:currentFYYear,
  };

  const last12MonthNames=datehelper.getLastMonthNames(24+todayDate.getMonth()-2);
  const last12MonthNamess = Object.fromEntries(
    Object.entries(last12MonthNames).filter(([key, value]) => !value.includes("2022"))
  );
  console.log(last12MonthNamess,"last12MonthNames")
  let startFYYear
  if(user.role == "FH"){
    //TODO We may need to support it from 2023.
    startFYYear = 2024
  } else{
    startFYYear = 2022
  }
  
  let fyyearList=[]
  fyyearList.push(startFYYear)
  let tmpFyYear=startFYYear
  while(tmpFyYear<currentFYYear){
    tmpFyYear=tmpFyYear+1
    fyyearList.push(tmpFyYear)
  }
  let businessDivisions=[]
  if(user.role==="KTR"){
    businessDivisions.push({'name':'SBL','value':'sbl'})
    businessDivisions.push({'name':'CoLending','value':'colending'})
    businessDivisions.push({'name':'Accounting','value':'accounting'})
   
  }else{
    businessDivisions.push({'name':'Kaabil Ops','value':'kaabil_ops'})
    businessDivisions.push({'name':'Gold Loans','value':'gold_loan'})
    businessDivisions.push({'name':'SBL','value':'sbl'})
  }
  
  if((roleChecker.hasAccountsAccess(user.role) || roleChecker.isHOCollectionTeamAccess(user.role) || roleChecker.isCustomerCareTeam(user.role) || [20710,20861,20947,20994].includes(user.emp_id) || roleChecker.isPIVGAccess(user.role)) || [4444,20349].includes(user.emp_id))
  {
    businessDivisions.push({'name':'Collection','value':'collection'})
  }
  if(roleChecker.isColendingAccess (user.role) || roleChecker.hasAccountsAccess(user.role) || roleChecker.isPIVGAccess(user.role))
  {
    businessDivisions.push({'name':'CoLending','value':'colending'})
 
  }
  if(roleChecker.hasAccountsAccess(user.role)  || roleChecker.isPIVGAccess(user.role))
  {
    businessDivisions.push({'name':'Accounting','value':'accounting'})
  }
  if((roleChecker.isLegalTeamAccess(user.role) || roleChecker.isHOCollectionApproverAccess(user.role)) && ![20349,4444].includes(user.emp_id))
  {
      businessDivisions.push({'name':'Legal','value':'legal'})
  }
  if(roleChecker.isHRDept(user.role))
  {
      businessDivisions.push({'name':'HR','value':'hr'})
  }
  if(user.role=="FH")
  {
    businessDivisions=[]
    businessDivisions.push({'name':'Kaabil Ops','value':'kaabil_ops'})
    businessDivisions.push({'name':'Finance Reports','value':'finance_report'})
    businessDivisions.push({'name':'Legal','value':'legal'})

  }

  if(roleChecker.isASMLevelAccess(user.role) ||roleChecker.isBMLevelAccess(user.role)|| roleChecker.isClusterSaleLevelAccess(user.role) || roleChecker.isCreditTeamAccess(user.role) )
  {
    businessDivisions=[]
    businessDivisions.push({'name':'Kaabil Ops','value':'kaabil_ops'})
    businessDivisions.push({'name':'SBL','value':'sbl'})

  }

 
  console.log(businessDivisions,"xnnnhssssssssssss")
  
  const validationSchema = Yup.object().shape({
    report_type: Yup.string().required("This field is required")
                  .min(1,"report type can't be blank"),
    input_date: Yup.date().max(maxDate,"Future dates not allowed"),
    end_date: Yup.date().max(maxDate,"Future dates not allowed"),
    
  });
  
  const handleOpen = async () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function onBusinessDivChange(businessDiv,setFieldValue){
    if(businessDiv=="gold_loan"){
      
      let tmpReportTypes=[];
      if(roleChecker.hasAccountsAccess(user.role) || roleChecker.isHOGoldAccess(user.role))
      {
        tmpReportTypes=[{'display':'Active Gold Loans','value':'active_gold_loans'},
                  {'display':'Gold Interest Due','value':'gold_interest_due'},
                  {'display':'Current Month EMI Date','value':'current_month_gold_emi_due'},
                  {'display':'Next Month EMI Date','value':'next_month_gold_emi_due'},
                  {'display':'Gold Payment List','value':'gold_payment_list'},
                  {'display':'Gold Disbursement List','value':'gold_disbursement_payment_list'},
                  {'display':'Month Interest Collection','value':'gold_month_interest_collection'},
                  {'display':'No Dues Loans','value':'gold_no_due_customer'},
                  {'display':'Gold Colending Payments','value':'gold_colender_payment'},
                  {'display':'Gold Renewed Loans Report','value':'gold_renewed_loan_report'},
                  {'display':'Gold Closed Loans Report','value':'gold_closed_loan_report'}];
      }
      if(roleChecker.isAdminAccess(user.role))
      {
        tmpReportTypes.push( {'display':'Closed Gold Loans','value':'closed_gold_loans'})
      }
      if(roleChecker.isGMLevelAccess(user.role))
      {
        tmpReportTypes.push( {'display':'Active Gold Loan Contact Details','value':'gold_active_contact'})
      }
      if(roleChecker.isPIVGAccess(user.role)){
         tmpReportTypes=[{'display':'Active Gold Loans','value':'active_gold_loans'},
                   {'display':'Current Month EMI Date','value':'current_month_gold_emi_due'},
                
                  ]
       }
      setReportTypes(tmpReportTypes);
      setFieldValue("business_div",businessDiv);
    }
    if(businessDiv=="sbl"){
      
      let tmpReportTypes=[];
      if(roleChecker.isGMLevelAccess(user.role) || roleChecker.isHOSalesApproverAccess(user.role) || roleChecker.isVPCLevelAccess(user.role) || roleChecker.isPIVGAccess(user.role) || user.role==="KTR")
      {
        tmpReportTypes=[{'display':'Branch Level Logins','value':'sbl_branch_level_login'},
        {'display':'SBL Review Report','value':'sbl_loan_review_report'},
        {'display':'SBL RO Productivity Report','value':'sbl_ro_productivity_report'},
        {'display':'SBL BT Loan Report','value':'get_bt_loan_report'},
        
        {'display':'Loan Under Processing','value':'sbl_loan_under_progress'},
        {'display':'Loan Stage Report','value':'sbl_loan_stage_report'},
        {'display':'Sanction Letter Report','value':'sbl_sanction_letters'},
        {'display':'SUD Report','value':'sbl_sud_report'},
        {'display':'Disbursement Report','value':'sbl_disbursement_report'},
        {'display':'Disbursement Payments','value':'sbl_disbursement_payments'},
        {'display':'Disbursement Partial Payment Pending','value':'sbl_partial_payment_pending'},
        {'display':'Loans in Collection List','value':'sbl_collection_list'},
        {'display':'SBL Payment Collected','value':'sbl_payment_list'},
        {'display':'Mobile Number NOT verified','value':'sbl_unverified_contacts'},
        // {'display':'Valuation Report','value':'get_valuation_report'},
        // {'display':'RCU Report','value':'get_rcu_report'},
        // {'display':'Legal Report','value':'get_legal_report'},
        {'display':'Valuation Legal & RCU Report','value':'rcu_valuation_legal_report'},
        {'display':'SBL Closed Loans Report','value':'get_closed_loans'},
        
        ];
      }
      // else if(roleChecker.isHOCreditApproverAccess(user.role) || roleChecker.isHOSalesApproverAccess(user.role))
      // {
      //     tmpReportTypes=[{'display':'Branch Level Logins','value':'sbl_branch_level_login'},
      //     {'display':'Loan Under Processing','value':'sbl_loan_under_progress'},
      //   {'display':'Loan Stage Report','value':'sbl_loan_stage_report'},
      //   {'display':'Sanction Letter Report','value':'sbl_sanction_letters'},
      //   {'display':'SUD Report','value':'sbl_sud_report'},
      //   {'display':'Disbursement Payments','value':'sbl_disbursement_payments'},
      //   {'display':'Valuation Report','value':'get_valuation_report'},
      //   {'display':'RCU Report','value':'get_rcu_report'},
      //   {'display':'Legal Report','value':'get_legal_report'},
      //     ];
      // }
      // else if(roleChecker.isHOOpsApproverAccess(user.role) || [20862].includes(user.emp_id))
      // {
      //     tmpReportTypes=[
      //       {'display':'Loan Under Processing','value':'sbl_loan_under_progress'},
      //   {'display':'Loan Stage Report','value':'sbl_loan_stage_report'},
      //   {'display':'SUD Report','value':'sbl_sud_report'},
      //   {'display':'Disbursement Report','value':'sbl_disbursement_report'},
      //   {'display':'Disbursement Payments','value':'sbl_disbursement_payments'},
      //   {'display':'Disbursement Partial Payment Pending','value':'sbl_partial_payment_pending'},
      //   {'display':'Loans in Collection List','value':'sbl_collection_list'},
      //   ];
      // } 
      // else if(roleChecker.isAreaLevelAccess(user.role) || roleChecker.isClusterSaleLevelAccess(user.role) || roleChecker.isBMLevelAccess(user.role) || roleChecker.isCreditTeamAccess(user.role) || ["EAZSM"].includes(user.role) )
      else if(roleChecker.isAreaLevelAccess(user.role) || roleChecker.isCreditTeamAccess(user.role) || ["EAZSM"].includes(user.role) )
      {
        tmpReportTypes=[{'display':'Branch Level Logins','value':'sbl_branch_level_login'},
        {'display':'Sanction Letter Report','value':'sbl_sanction_letters'},
        {'display':'SUD Report','value':'sbl_sud_report'},
        {'display':'Disbursement Report','value':'sbl_disbursement_report'},
        {'display':'Valuation Legal & RCU Report','value':'rcu_valuation_legal_report'},
        // {'display':'Valuation Report','value':'get_valuation_report'},
        // {'display':'RCU Report','value':'get_rcu_report'},
        // {'display':'Legal Report','value':'get_legal_report'},
         ]
      }
      else if(roleChecker.isBMLevelAccess(user.role))
      // else if(roleChecker.isAreaLevelAccess(user.role) || roleChecker.isCreditTeamAccess(user.role) || ["EAZSM"].includes(user.role) )
      {
        tmpReportTypes=[{'display':'Branch Level Logins','value':'sbl_branch_level_login'},
        {'display':'Sanction Letter Report','value':'sbl_sanction_letters'},
        {'display':'SUD Report','value':'sbl_sud_report'},
        {'display':'Disbursement Report','value':'sbl_disbursement_report'},
        // {'display':'Valuation Report','value':'get_valuation_report'},
        // {'display':'RCU Report','value':'get_rcu_report'},
        // {'display':'Legal Report','value':'get_legal_report'},
         ]
      }
      else if(roleChecker.hasAccountsAccess(user.role))
      {
        tmpReportTypes=[{'display':'Branch Level Logins','value':'sbl_branch_level_login'},
                        {'display':'Sanction Letter Report','value':'sbl_sanction_letters'},
                        {'display':'Disbursement Report','value':'sbl_disbursement_report'},
                        {'display':'Disbursement Partial Payment Pending','value':'sbl_partial_payment_pending'},
                        {'display':'Disbursement Payments','value':'sbl_disbursement_payments'},
                      ]
                      if([20584].includes(user.emp_id)){
                        tmpReportTypes.push({'display':'Loans in Collection List','value':'sbl_collection_list'}) 
                      }
      } 
      // if([20349,10001].includes(user.emp_id)){
      //   tmpReportTypes.push({'display':'SBL DPD & POS Report','value':'sbl_dpd_pos_report'}) 
      
      // }
      if([204060,20406].includes(user.emp_id) || ["Admin","CFO"].includes(user.role)){
        tmpReportTypes.push({'display':'SBL DPD & POS Report Month Wise','value':'sbl_month_wise_pos_dpd_report'}) 
        tmpReportTypes.push({'display':'SBL Demand Report Month Wise','value':'sbl_month_wise_demand_report'}) 

      
      }


      if(["COLM"].includes(user.role)){
        tmpReportTypes.push({'display':'Sanction Letter Report','value':'sbl_sanction_letters'}) 

      }
      setReportTypes(tmpReportTypes);
      setFieldValue("business_div",businessDiv);
    }
    if(businessDiv=="hr"){
      
      let tmpReportTypes=[];
      if(roleChecker.isHRDept(user.role) || roleChecker.isGMLevelAccess(user.role))
      {
          tmpReportTypes=[{'display':'SBL Review Report','value':'sbl_loan_review_report'},
          {'display':'Collection Team Incentive','value':'hr_collection_incentive'},
          {'display':'Sanction Letter Report','value':'sbl_sanction_letters'}];

      }
      setReportTypes(tmpReportTypes);
      setFieldValue("business_div",businessDiv);
    }
    if(businessDiv=="collection"){
      
      let tmpReportTypes=[];
      if(roleChecker.isGMLevelAccess(user.role)  || roleChecker.isHOCollectionTeamAccess(user.role) || roleChecker.isCustomerCareTeam(user.role) || [20710,20861,20947,20994].includes(user.emp_id) || roleChecker.isPIVGAccess(user.role))
      {
        if([20861,20947].includes(user.emp_id)){
          tmpReportTypes=[
            {'display':'Field Visit Report','value':'collection_field_visits'},    
            {'display':'Telecalling PTP Report','value':'collection_telecalling_report'} ,
            {'display':'Payments Report','value':'kaabil_ecollect_payments'},

        ];
        }else if(roleChecker.isPIVGAccess(user.role) ){
          tmpReportTypes=[
            {'display':'Field Visit Report','value':'collection_field_visits'},    
            {'display':'Telecalling PTP Report','value':'collection_telecalling_report'},    
        {'display':'Loans in Collection List','value':'sbl_collection_list'},
        {'display':'EMI Collection details','value':'sbl_emi_collection_mode'},
         {'display':'Enach collection report','value':'kaabil_enach_collection_report'},
        {'display':'NACH Collection details NOT available','value':'sbl_collection_mode_not_available'},
         ];
         if([20858].includes(user.emp_id)){
          tmpReportTypes.push({'display':'DPD Report','value':'sbl_dpd_report'})
          tmpReportTypes.push({'display':'Demand Report','value':'collection_demand_report'}) 
        }
        
        }

        else{

          tmpReportTypes=[
            {'display':'Field Visit Report','value':'collection_field_visits'},    
            {'display':'Telecalling PTP Report','value':'collection_telecalling_report'},    
        {'display':'Loans in Collection List','value':'sbl_collection_list'},
        {'display':'EMI Collection details','value':'sbl_emi_collection_mode'},
        {'display':'SBL EMI Received report','value':'sbl_emi_received_report'},
        {'display':'Collection NOT assigned report','value':'collection_not_assigned'},
        {'display':'Enach collection report','value':'kaabil_enach_collection_report'},
        {'display':'NACH Collection details NOT available','value':'sbl_collection_mode_not_available'},
        {'display':'Receipt Tracking Report','value':'receipt_tracking_report'}
        ];
        
        if(["CLM","CFO","CEO","Admin"].includes(user.role)){
          tmpReportTypes.push({'display':'DPD Report','value':'sbl_dpd_report'})    
        }
        if(["CFO","CEO","Admin"].includes(user.role)){
          tmpReportTypes.push({'display':'Demand Report','value':'collection_demand_report'})  
        }
        if(roleChecker.isAdminAccess(user.role))
        {
           tmpReportTypes.push( {'display':'Field Collection Report','value':'collection_amount_report'});    
           tmpReportTypes.push( {'display':'Applied ENach not in Collection Sheet','value':'sbl_enach_success_apply_not_collection'});
           tmpReportTypes.push( {'display':'Active ENach not in Collection Sheet','value':'sbl_active_enach_not_collection'});
        }
        else if (roleChecker.isGMLevelAccess(user.role))
        {
          tmpReportTypes.push( {'display':'Field Collection Report','value':'collection_amount_report'});    
          
        }
        else
        {
          tmpReportTypes.push( {'display':'Field Collection Report','value':'collection_amount_report'});    
        }
      }
        
      }
      setReportTypes(tmpReportTypes);
      setFieldValue("business_div",businessDiv);
    } 
    if(businessDiv=="kaabil_ops"){
      
      let tmpReportTypes=[];
      if(roleChecker.hasAccountsAccess(user.role) || roleChecker.isHOLevelAccess(user.role) || roleChecker.isASMLevelAccess(user.role) || roleChecker.isClusterSaleLevelAccess(user.role) || roleChecker.isBMLevelAccess(user.role) || roleChecker.isCreditTeamAccess(user.role) || [20710].includes(user.emp_id) || ["EAZSM"].includes(user.role) || user.role==="ASM" || user.role==="RSM" || user.role==="ZSM")
      {
        tmpReportTypes=[ {'display':'ICICI Enach List','value':'kaabil_icici_enach_list'},
        {'display':'AU Enach List','value':'kaabil_au_enach_list'}];
      }
      setReportTypes(tmpReportTypes);
      setFieldValue("business_div",businessDiv);
    }
    if(businessDiv=="colending"){
      
      let tmpReportTypes=[];
      if(roleChecker.hasAccountsAccess(user.role) || roleChecker.isColendingAccess(user.role) || roleChecker.isPIVGAccess(user.role) || user.role==="KTR")
      {
        tmpReportTypes=[{'display':'SBL Colender Monthly Payout','value':'colender_sbl_monthly_payout_list'},
        {'display':'CoLending Report','value':'kaabil_colending_report'},
        ];
      }
      setReportTypes(tmpReportTypes);
      setFieldValue("business_div",businessDiv);
    }
    if(businessDiv=="accounting"){
      
      let tmpReportTypes=[];

      
      if(roleChecker.hasAccountsAccess(user.role))
      {
        tmpReportTypes=[{'display':'Term Loan List','value':'accounting_term_loans_list'},
          {'display':'RPS Failed Loans','value':'bulk_rps_report'},
          // {'display':'Bulk Nach Reconciliation report','value':'bulk_nach_recon_report'},
        {'display':'Term Loan Interest Statement','value':'term_loan_expense_report'},
        {'display':'Payments Report','value':'kaabil_ecollect_payments'},
        // {'display':'Cash Payments','value':'kaabil_cash_payment'},
        {'display':'Reconcilation Report','value':'kaabil_reconcilation_list'},
        {'display':'Enach collection report','value':'kaabil_enach_collection_report'},
        {'display':'Non reconciled payments','value':'kaabil_non_reconcile_report'},
        {'display':'Virtual Account list','value':'kaabil_virtual_accounts'},
        {'display':'SBL Colender Monthly Payout','value':'colender_sbl_monthly_payout_list'},
        {'display':'SBL Colender Interest Statement','value':'colender_expense_report'},
        {'display':'Login Fees Income Report','value':'login_fees_report'},
        {'display':'IMD Fees Income Report','value':'imd_fees_report'}, 
        {'display':'Automated Disbursement Payment Report','value':'automated_disbursement_payment_report'},
        {'display':'SBL Cash inflow report','value':'sbl_cash_inflow_report'},
        {'display':'SBL Income Report','value':'sbl_income_statement'},
        {'display':'Gold Loan Income Report','value':'gold_income_expense_report'},
        {'display':'Gold Monthwise Report','value':'gold_account_report_month_wise'},
        {'display':'Insurance Report','value':'create_insurance_report'}
        
        
        
       ];
      }else if(roleChecker.isPIVGAccess(user.role)){
        tmpReportTypes=[
         {'display':'Payments Report','value':'kaabil_ecollect_payments'},
        // {'display':'Cash Payments','value':'kaabil_cash_payment'},
        {'display':'Reconcilation Report','value':'kaabil_reconcilation_list'},
        {'display':'Enach collection report','value':'kaabil_enach_collection_report'},
        {'display':'Non reconciled payments','value':'kaabil_non_reconcile_report'},
         {'display':'Login Fees Income Report','value':'login_fees_report'},
        {'display':'IMD Fees Income Report','value':'imd_fees_report'}, 
         {'display':'SBL Cash inflow report','value':'sbl_cash_inflow_report'},
         {'display':'Gold Monthwise Report','value':'gold_account_report_month_wise'},
         
       ];
      }
      else if(user.role==="KTR"){
        tmpReportTypes=[
          {'display':'Term Loan List','value':'accounting_term_loans_list'},
          {'display':'Virtual Account list','value':'kaabil_virtual_accounts'},
          {'display':'SBL Colender Interest Statement','value':'colender_expense_report'},
         {'display':'Login Fees Income Report','value':'login_fees_report'},
        {'display':'IMD Fees Income Report','value':'imd_fees_report'},        
       ];
      }
      if(roleChecker.isCFOAccess(user.role))
      {
        tmpReportTypes=[...tmpReportTypes,{'display':'SBL Interest Income Report','value':'sbl_income_report'},
        {'display':'SBL Closed Loans Report','value':'get_closed_loans'},
        // {'display':'SBL DPD Report','value':'sbl_dpd_report'},
      ]}

         
      
      setReportTypes(tmpReportTypes);
      setFieldValue("business_div",businessDiv);
    }
    if(businessDiv=="legal"){ 
      
      let tmpReportTypes=[];
      if(roleChecker.isLegalTeamAccess(user.role) || roleChecker.isHOCollectionApproverAccess(user.role))
      {
        tmpReportTypes=[{'display':'Court Case List','value':'legal_court_cases_list'},
                        {'display':'Field Collection Report','value':'collection_amount_report'}];
      }
      setReportTypes(tmpReportTypes);
      setFieldValue("business_div",businessDiv);
    }
    if(businessDiv=="finance_report"){ 
      
      let tmpReportTypes=[];
      if(user.role=="FH")
      {
        tmpReportTypes=[
          {'display':'GL Outstanding Amount','value':'gl_fy24_outstanding'},
          {'display':'GL Income Statement','value':'gl_fy24_incomestatement'},
          {'display':'SBL Interest Income', 'value':'sbl_fy24_interestincome'},
          {'display':'SBL Branch Level Login','value':'sbl_fy24_branch_level_login'},
          {'display':'SBL Sanction Letter','value':'sbl_fy24_sanction_letters'},
          {'display':'SBL Outstanding Amount','value':'sbl_fy24_outstanding'},
          {'display':'SBL Disbursement Payments','value':'sbl_fy24_disbursement_payments'},
          {'display':'SBL IMD Fees','value':'sbl_fy24_imd_fees'},
        ];
      }
      setReportTypes(tmpReportTypes);
      setFieldValue("business_div",businessDiv);
    }

  }

  function onReportTypeChange(reportType,setFieldValue){
    if(reportType=="gold_loan_portfolio" || reportType=="sbl_dpd_pos_report")
    {
        setIsDateInput(true);
    }
    else
    {
        setIsDateInput(false); 
    }

    if(["hr_collection_incentive","collection_amount_report","sbl_emi_received_report","colender_sbl_monthly_payout_list","kaabil_enach_collection_report","sbl_income_report","sbl_month_wise_pos_dpd_report","sbl_month_wise_demand_report"].includes(reportType))
    {
        setIsMonthInput(true);
    }
    else
    {
        setIsMonthInput(false); 
    }
    if(["gold_account_report_month_wise", "sbl_fy24_interestincome", "gl_fy24_outstanding", "gl_fy24_incomestatement", "sbl_fy24_branch_level_login", "sbl_fy24_sanction_letters", "sbl_fy24_outstanding", "sbl_fy24_disbursement_payments", "sbl_fy24_imd_fees"].includes(reportType))
    {
        setIsYearInput(true);
    }
    else
    {
        setIsYearInput(false); 
    }

    
    if(["gold_income_expense_report","gold_income_expense_report_legacy","colender_expense_report","term_loan_expense_report","sbl_loan_under_progress","sbl_branch_level_login","collection_field_visits","sbl_loan_review_report","sbl_ro_productivity_report","get_bt_loan_report","get_rcu_report","get_valuation_report","get_legal_report", "sbl_disbursement_report","sbl_disbursement_payments","sbl_sanction_letters","kaabil_ecollect_payments","kaabil_cash_payment","sbl_payment_list","sbl_income_statement","collection_telecalling_report","login_fees_report","imd_fees_report","sbl_income_master_report","automated_disbursement_payment_report","sbl_cash_inflow_report","receipt_tracking_report","gold_closed_loan_report","gold_renewed_loan_report","kaabil_non_reconcile_report" ,"create_insurance_report","kaabil_colending_report","rcu_valuation_legal_report"].includes(reportType))
    {
        setIsDateRangeInput(true);
    }
    else
    {
        setIsDateRangeInput(false); 
    }
    if(["collection_demand_report"].includes(reportType))
      {
          setIsFirstDateRangeInput(true);
      }
      else
      {
        setIsFirstDateRangeInput(false); 
      }


    setFieldValue("report_type",reportType);
  }
  

  
  async function onSubmit(fields, { setSubmitting }) {
    setSubmitting(true);
    const inputValues={
        ...fields,
        report_status:'Processing',
    }
    console.log(inputValues);
    reportsService.create(inputValues)
    .then((report)=> {
       console.log(report);
       alert("report with id "+report._id+" created");
       handleClose();
       window.location.reload();
    })
    .catch((err)=> {
      window.alert("problem creating error");
      console.log(err);
    })

    setSubmitting(false);
  }

  return (
    <div>
     
      <Button onClick={handleOpen} variant="outlined" color="primary">
        Request Report
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">New Report Request</h2>
           
              <Formik
                innerRef={formikRef}
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                id="transition-modal-description"
              >
              {({ values,errors, touched, isSubmitting, setFieldValue }) => {
                  return (
                    <Form>
                    <div>
                      <div className="form-row">
                      <div className="form-group col">
                      <label>Business Division:</label>
                      </div>
                        <div className="form-group col">
                          
                          <Field
                            name="business_div"
                            style={{width:"150px",marginLeft:"10px",padding:"5px"}}
                            as="select"
                            onChange={(e)=> onBusinessDivChange(e.target.value,setFieldValue)}
                            className={
                              "form-control" +
                              (errors.em && touched.em ? " is-invalid" : "")
                            }
                          >
                            <option value="" disabled></option>
                            {businessDivisions.map((bd,idx)=> {
                              return <option key={idx} value={bd['value']}>{bd['name']}</option>
                            })}

                          </Field>
                          <ErrorMessage
                            name="business_div"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      
                        </div>
                        <div className="form-row">
                        <div className="form-group col">
                      <label>Report Type:</label>
                      </div>
                       
                        <div className="form-group col">
                         
                          <Field
                            name="report_type"
                            style={{width:"300px",marginLeft:"10px",padding:"5px"}}
                            as="select"
                            onChange={(e)=> onReportTypeChange(e.target.value,setFieldValue)}
                            className={
                              "form-control" +
                              (errors.em && touched.em ? " is-invalid" : "")
                            }
                          >
                            <option value="" disabled></option>
                            {reportTypes.map((rt,idx)=> {
                               return (
                                 <option key={rt.value} value={rt.value}>{rt.display}</option>
                               )
                            })
                            }
                          </Field>
                          <ErrorMessage
                            name="report_type"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        </div>
                        {isDateInput && (
                          <div className="form-row">
                          <div className="form-group col">
                            Select Date:
                            <Field
                              type="date"
                              name="input_date"
                              max={maxDate}
                              className={
                                "form-control" +
                                (errors.em && touched.em ? " is-invalid" : "")}   
                             />
                              <ErrorMessage
                            name="date_input"
                            component="div"
                            className="invalid-feedback"
                          />
                          </div>
                          </div>
                        )}
                        {isMonthInput && (
                          <div className="form-row">
                             <div className="form-group col">
                             Select Month:
                          </div>
                          <div className="form-group col">
                           
                          { [20406,40000].includes(user.emp_id) ? <Select
                              name="month"
                              style={{width:"150px",marginLeft:"10px",padding:"5px"}}
                              onChange={(e)=> setFieldValue("month",e.target.value)}
                              className={
                                "form-control" +
                                (errors.em && touched.em ? " is-invalid" : "")}   
                             >
                            {Object.keys(last12MonthNamess).map((k,idx)=> {
                              return (
                                <option value={k}>{last12MonthNamess[k]}</option>
                              )
                            })}  
                            </Select>:<Select
                              name="month"
                              style={{width:"150px",marginLeft:"10px",padding:"5px"}}
                              onChange={(e)=> setFieldValue("month",e.target.value)}
                              className={
                                "form-control" +
                                (errors.em && touched.em ? " is-invalid" : "")}   
                             >
                            {Object.keys(last12MonthNames).map((k,idx)=> {
                              return (
                                <option value={k}>{last12MonthNames[k]}</option>
                              )
                            })}  
                            </Select>}
                              <ErrorMessage
                            name="month"
                            component="div"
                            className="invalid-feedback"
                          />
                          </div>
                         
                          </div>
                        )}
                        {isYearInput && (
                          <div className="form-row">
                             <div className="form-group col">
                             Select Financial Year:
                          </div>
                          <div className="form-group col">
                           
                            <Select
                              name="year"
                              style={{width:"150px",marginLeft:"10px",padding:"5px"}}
                              onChange={(e)=> setFieldValue("year",e.target.value)}
                              className={
                                "form-control" +
                                (errors.em && touched.em ? " is-invalid" : "")}   
                             >
                            {fyyearList.map((k,idx)=> {
                              return (
                                <option value={k}>{k}</option>
                              )
                            })}  
                            </Select>
                              <ErrorMessage
                            name="year"
                            component="div"
                            className="invalid-feedback"
                          />
                          </div>
                         
                          </div>
                        )}
                        
                        {isFirstDateRangeInput && (
                          <div className="form-row">
                          <div className="form-group col">
                            Select Date:
                            <Field
                              type="date"
                              name="end_date"
                              max={maxDate}
                              value={values.end_date}
                              className={
                                "form-control" +
                                (errors.em && touched.em ? " is-invalid" : "")}   
                             />
                              <ErrorMessage
                            name="end_date"
                            component="div"
                            className="invalid-feedback"
                          />
                          </div>
                         
                          </div>
                        )}

                        {isDateRangeInput && (
                          <div className="form-row">
                          <div className="form-group col">
                            Start Date:
                            <Field
                              type="date"
                              name="start_date"
                              max={maxDate}
                              className={
                                "form-control" +
                                (errors.em && touched.em ? " is-invalid" : "")}   
                             />
                              <ErrorMessage
                            name="start_date"
                            component="div"
                            className="invalid-feedback"
                          />
                          </div>
                          <div className="form-group col">
                            End Date:
                            <Field
                              type="date"
                              name="end_date"
                              max={maxDate}
                              value={values.end_date}
                              className={
                                "form-control" +
                                (errors.em && touched.em ? " is-invalid" : "")}   
                             />
                              <ErrorMessage
                            name="end_date"
                            component="div"
                            className="invalid-feedback"
                          />
                          </div>
                          </div>
                        )}
                        <hr/>
                        <div className="row justify-content-around">
                        <Fab
                          type="submit"
                          disabled={isSubmitting}
                          color="primary"
                          aria-label="save"
                        >
                          {isSubmitting && (
                            <span className="spinner-border spinner-border-sm mr-1"></span>
                          )}
                          <SaveIcon />
                        </Fab>
                      </div>
                      </div>
                      </Form>
                  );
                 }}
              </Formik>
          
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
