import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import { Button, TextField } from "@material-ui/core";
import { Alert, AlertTitle } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { ApprovalRequestFilter } from "../../MakerCheckerFramework/ApprovalRequestFilter";
import { employeeService,customerService } from "../../../_services";
import swal from 'sweetalert';
import { allcloudService } from '../../../_services/allcloud.service';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));


const LoanClosureRequest = (props) => {
  const classes = useStyles();
  const loanData = props.loan_data;
  const [open, setOpen] = useState(false);
  const [comments, setComments] = useState('');
  const [settlement, setSettlement] = useState(false);
  const [foreclosure, setForeclosure] = useState(false);
  const [fullAmountement, setFullAmount] = useState('');
  const refreshParent = props.refreshParent;
  const user = employeeService.userValue;
  const [checkBalance , setCheckBalance]= useState()
  const [getLatestEmiReceivedDate , setGetLatestEmiReceivedDate]= useState("")
  const [getBalanceRemaining , setGetBalanceRemaining]= useState("")
  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);
  const handleComment = (e) => setComments(e.target.value)
  const handleSettlement = () => {
    setSettlement(true);
    setForeclosure(false);
  };
  const handleForeclosure = () => {
    setSettlement(false);
    setForeclosure(true);
  };
  const handleFullAmount = (e) => setFullAmount(e.target.value)
console.log(getLatestEmiReceivedDate ,"ggggggggggggggggg")
console.log(getBalanceRemaining ,"getBalanceRemaininggetBalanceRemaining")
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    height: 300,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    overflow: "scroll",
    paddingBottom: "30px",
  };


  const getAllCloudFinanceId = async () => {
    try {
      const data = await allcloudService.getAllCloudFinanceId({ application_id: props?.loan_data?.application_id, });
      setCheckBalance(data.emi_schedule)
      if(data.emi_schedule.length > 0){
        const filteredData = data.emi_schedule.filter(entry => entry.emi_received_date);
      const sortedData = filteredData.sort((a, b) => new Date(b.emi_received_date) - new Date(a.emi_received_date));
      const latestEntry = sortedData.length ? sortedData[0] : null;
      const latestEmiReceivedDate = latestEntry ? latestEntry.emi_received_date : null;
      const balanceRemaining = latestEntry ? latestEntry.balance_remaining : null;
      setGetLatestEmiReceivedDate(latestEmiReceivedDate)
      setGetBalanceRemaining(balanceRemaining)
      }
    } catch (err) {
      console.log(err);
    }
  };

  const UpdateRpss = async () => {
    try {
      const data = await customerService.UpdateRps({ application_id: props?.loan_data?.application_id, });
      console.log(data.status)
    } catch (err) {
      console.log(err);
    }
  };

  const loanClosureRequest = async (e) => {
    if (!comments.trim()) {
      swal({
        icon: 'error',
        title: 'Empty Comment',
        text: 'Please enter a comment to proceed.',
        confirmButtonText: 'OK'
      });
      return;
    }

   
    
    
    
   if (getBalanceRemaining > 0 ||getBalanceRemaining===null ) {
    let params = {
      next_stage_emp_id: user.emp_id,
      stage: 'Closed',
      sub_stage: '',
      comments: comments,
      settlement: settlement,
      foreclosure: foreclosure,
      fullAmountement: fullAmountement,
      updated_by_emp_id: user.emp_id
    }
    await ApprovalRequestFilter.RequestFilter({
      service: 'loanService',
      api: 'changeStage',
      id: loanData._id,
      task: 'Close loan',
      updated_fields: ['stage'],
      sensitive_fields: [],
      new_values: params,
      old_values: {
        stage: loanData.stage,
      },
      identification_key: 'Loan Number',
      identification_value: loanData.application_id,
      emp_id: user.emp_id,
      approval_needed: true,
      approved: false,
      approval_access: 'isAccountChecker',
      product: 'sbl'
    })
      .then((res) => {
        console.log(res);
        window.alert('Loan closure request sent to the concerned authority');
        if (refreshParent) {
          handleClose();
          refreshParent();

        }
        else {
          window.location.reload();
        }
      })
      .catch((err) => {
        console.log(err);
        window.alert(err);
      });
   }
   else if(getBalanceRemaining<= 0){
        swal({
          icon: 'success',
          title: 'Completed Payment',
          text: 'Moving to close loan.',
          confirmButtonText: 'OK'
        });
        let params = {
          next_stage_emp_id: user.emp_id,
          stage: 'Closed',
          sub_stage: '',
          comments: comments,
          updated_by_emp_id: user.emp_id
        }
        await ApprovalRequestFilter.RequestFilter({
          service: 'loanService',
          api: 'changeStage',
          id: loanData._id,
          task: 'Close loan',
          updated_fields: ['stage'],
          sensitive_fields: [],
          new_values: params,
          old_values: {
            stage: loanData.stage,
          },
          identification_key: 'Loan Number',
          identification_value: loanData.application_id,
          emp_id: user.emp_id,
          approval_needed: true,
          approved: false,
          approval_access: 'isAccountChecker',
          product: 'sbl'
        })
          .then((res) => {
            console.log(res);
            window.alert('Loan closure request sent to the concerned authority');
            if (refreshParent) {
              handleClose();
              refreshParent();
    
            }
            else {
              window.location.reload();
            }
          })
          .catch((err) => {
            console.log(err);
            window.alert(err);
          });
      }
    }
  
  const handleCloseLoan = () => {
    handleOpen();
    UpdateRpss();
    getAllCloudFinanceId();
  }
  return (
    <div>
      <Button color='secondary' style={{ marginTop: '6px' }} onClick={handleCloseLoan} variant="outlined">
        Close loan
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography style={{ margin: "auto" }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {(getBalanceRemaining===null || getBalanceRemaining > 0)  &&  
           ( <><h4>Balance Remaining Confirmation</h4>
            <div style={{display:"flex",justifyContent:"space-around"}}>
            <div class="loan-closure-option">
              <label for="radio1" class="loan-closure-label"> <input type="radio" id="radio1" name="option" value="Settlement" onChange={handleSettlement} />
              &nbsp;  Settlement</label>
          </div>
          <div class="loan-closure-option" style={{marginLeft:"1rem"}}> 
          <label for="radio2" class="loan-closure-label"> <input type="radio" id="radio2" name="option" value="Foreclosure" onChange={handleForeclosure} />
          &nbsp;  Foreclosure</label><br/>
          </div>
            </div>
              
         
          <label for="fullAmount">Please Enter Amount :
          <input type="number" id="fullAmount" name="fullAmount" min="0" step="any" style={{marginBottom:"1rem"}} onChange={handleFullAmount} ></input>
          </label></>)
}
              <label>Comment:
                <textarea name="comment" cols="35" rows="2" onChange={handleComment} required></textarea>
              </label>
              <Button color='secondary' style={{ width: '170px', marginTop: '10px' }} variant="contained" type="button" onClick={() => loanClosureRequest()}>Close Loan</Button>
            </div>
          </Typography>
        </Box>
      </Modal>

  
    </div>
  );
};

export default LoanClosureRequest;