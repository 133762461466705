import React, { useState,useEffect } from 'react'
import datehelper from '../../../../_helpers/datehelper';
import roleChecker from '../../../../_helpers/rolechecker';
// import urlhelper from '../../../../_helpers/urlhelper';
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "../../../../css/common.css"
import { employeeService } from '../../../../_services';
import {history} from '../../../../_helpers'
import { Button } from '@material-ui/core';
import { Edit ,Delete} from "@material-ui/icons";
import PrintIcon from '@material-ui/icons/Print';
import { TabContext } from '../TabBar';
import { useContext } from "react";
import { sblLetter } from "../../../../_services/letter.services";


const CamLetter = () => {
  const [employeeList, setEmployeeList] = useState({})
  let TabContextValue = useContext(TabContext)
  let cust = TabContextValue.cust
  console.log("cust is",cust)
    const user = employeeService.userValue;


    const print = (element) => {
        history.push(`/home/sblletters/letter/show/camletter`, element)
      }
    
      const edit = (element) => {
        history.push(`/home/sblletters/letter/edit/camletter`, { id: element._id })
      }
 const verifyCamLetter =(id)=>{
    console.log("cam letter verified",id)
    sblLetter.verifyCamLetter(id)
    .then((res)=>{
      console.log("res is",res)
      window.location.reload()
    })
    .catch(err=>console.log("err",err))
 }

 const resetCamLetter =(id)=>{
  console.log("cam letter reset",id)
  sblLetter.resetCamLetter(id)
  .then((res)=>{
    console.log("res is",res)
    window.location.reload()
  })
  .catch(err=>console.log("err",err))
}
 async function getAllEmployees() {
  await employeeService.getAll()
      .then((res) => {
          let list = {};
          res.map(e => {
              list[e.emp_id] = e.first_name + ' ' + e.last_name
          })
          setEmployeeList(list)
      })
}
const deleteCamLetter=(id)=>{
  console.log("cam letter delete ",id)
  sblLetter.deleteCamLetter(id)
  .then((res)=>{
    console.log("res is",res)
    window.location.reload()
  })
  .catch(err=>console.log("err",err))
}

useEffect(() => {
   
  getAllEmployees()
}, [])
  return (
    <div>
        <h3>CAM Letter List</h3>
        {( roleChecker.isCreditTeamAccess(user.role) || roleChecker.isHOCreditTeamAccess(user.role) || roleChecker.isGMLevelAccess(user.role)) && (!cust.cam_letters ||  cust.cam_letters.length ==0) && (
          <Button
            color="primary"
            onClick={() =>
              history.push(`/home/sblletters/letter/camletterform`, cust._id)
            }
          >
            Fill CAM Letter
          </Button>)}
        <br />

        {cust.cam_letters && cust.cam_letters.length > 0 ?
          <div className='table-border'>
            <Table className="table table-hover">
              <Thead>
                <Tr>
                  <Th className='table-row-th' style={{ width: "25%" }}>Date</Th>
                  <Th className='table-row-th' style={{ width: "25%" }}>Name</Th>
                  <Th className='table-row-th' style={{ width: "25%" }}>Loan No</Th>
                  <Th className='table-row-th' style={{ width: "15%" }}>Sanction Amount</Th>
                  <Th className='table-row-th' style={{ width: "10%" }}>Edit</Th>
                  <Th className='table-row-th' style={{ width: "10%" }}>Print</Th>
                  <Th className='table-row-th' style={{ width: "10%" }}>Varify</Th>
                  <Th className='table-row-th' style={{ width: "10%" }}>Verified By</Th>
                  <Th className='table-row-th' style={{ width: "10%" }}>Action</Th>

                </Tr>
              </Thead>
              <Tbody>
                {cust.cam_letters && cust.cam_letters.map((element) => {
                  return ( (!element.status || element.status!="Rejected") &&  <Tr key={element._id}>
                    <Td className='table-row-td' style={{ width: "25%" }}>{datehelper.displayDate(new Date(element.createdAt))}</Td>
                    <Td className='table-row-td' style={{ width: "25%" }}>{element.applicant}</Td>
                    <Td className='table-row-td' style={{ width: "25%" }}>{element.application_id}</Td>
                    <Td className='table-row-td' style={{ width: "15%" }}>{element.sanction_amount}</Td>
                    <Td className='table-row-td' style={{ width: "10%" }}>
                   { (roleChecker.isCreditTeamAccess(user.role) || roleChecker.isHOCreditTeamAccess(user.role) || roleChecker.isGMLevelAccess(user.role) || roleChecker.isColendingTeamAccess(user.role) ) && (!element.verify_details || !element.verify_details.is_verified) &&<Button className='btn btn-primary' onClick={() => edit(element)} ><Edit /> </Button>}
                    </Td>
                    <Td className='table-row-td' style={{ width: "10%" }}><Button className='btn btn-primary' onClick={() => print(element)} >
                    {((!element.verify_details || element.verify_details.is_verified) || (roleChecker.isHOCreditApproverAccess(user.role) )) && <> <PrintIcon /> </>}
                   </Button></Td>
                   <Td className='table-row-td'>{(element.verify_details &&  !element.verify_details.is_verified)?<>
                                {roleChecker.isHOCreditApproverAccess(user.role) && user.emp_id!=element.created_emp_id ? <Button variant="contained" color="primary" onClick={() => {
                                  if (window.confirm("Are you sure you want to verify?")) {
                                                   verifyCamLetter(element._id);
    }
                                    }}>Verify</Button>:<Button disabled>Pending</Button>}
                            </>:<>
                            {
                                (element.verify_details && element.verify_details.is_verified) ? <> <p style={{color:'green'}}>Verified</p>
                                {roleChecker.isGMLevelAccess(user.role) && <Button style={{ background:"red",color:"white"}} variant='contained'  onClick={() => {
                                  if (window.confirm("Are you sure you want to reset ?")) {
                                                   resetCamLetter(element._id);
    }
                                    }} >Reset</Button>}
                                </>:null
                            }
                               
                            </>} </Td>
                            <Td className='table-row-td'>
                                {
                                    (element.verify_details && element.verify_details.is_verified) && <p>{employeeList[element.verify_details.verified_by_emp_id]}</p>
                                }
                            </Td>
                            <Td className='table-row-td'>
                                 {(!element.verify_details  || !element.verify_details.is_verified) && [20638,20675].includes(user.emp_id)  && <Button onClick={()=>{if(window.confirm('Are you sure! you want to delete this cam letter ?')){
                                  deleteCamLetter(element._id)
                                 }}}><Delete/></Button>}
                            </Td>
                  </Tr>)
                })}

              </Tbody>
            </Table>
          </div> : <span style={{ color: "red" }}> No CAM letter generated.</span>
        }
    </div>
  )
}

export default CamLetter