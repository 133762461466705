import React from "react";
import { useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import { count } from "rxjs";
import kaabillogo from "../../../assets/kaabil.png";
import kaabilfooter from "../../../assets/kaabil_footer2.png";

const NocLetter = (props) => {
  const NocLetterRef = React.useRef();

  const handleSubmissionPrint = useReactToPrint({
    content: () => NocLetterRef.current,
    pageStyle: "print",
  });

  let date = new Date();
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const yy = date.getFullYear();
  const mm = date.getMonth();
  const dd = date.getDate();

  date = months[mm] + " " + dd + "," + " " + yy;

  // date = dd + "/" + mm + "/" + yy

  const data = props.location.state;

  let temp = new Date();
  console.log(props);

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <button onClick={handleSubmissionPrint}>Print Letter</button>
      </div>
      .
      <div ref={NocLetterRef}>
        <img
          src={kaabillogo}
          style={{ height: "150px", marginLeft: "30%" }}
          alt="KFPL Logo"
        />

        <div
          style={{
            marginTop: "200px",
            fontSize: "22px",
            textAlign: "justify",
            marginLeft: "53px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <span>Dated: {date}</span>
            <span style={{ marginRight: "20px" }}>
              Letter Id: {data.letter_id}
            </span>
          </div>
          <br />
          <br />

          <div>
            {data.name} S/o {data.sonof}
            <br />
            {data.address}
          </div>
          <br />

          <div>
            <b>
              Sub: No dues Certificate Secured Business Loan A/c.
              {data.application_id} Rs.{data.amount}/-
            </b>
          </div>
          <br />

          <div>Dear Sir/Madam,</div>
          <br />

          <div style={{margin:"auto"}}>
            This is to certify that {data.name} S/o {data.sonof} &nbsp;
            Maintaining loan account no.{data.application_id} amount Rs.
            {data.amount}/- with Kaabil Finance Private Limited there is no
            outstanding liability and the said loan has been fully paid with up
            to date.
            <br />
            <br />
            {data.colender === "" ? (
    null
  ) : (
    <div>
      This is to certify that the loan has been sanctioned under co-lending
      with <b>{data.colender}</b> and the same has been cleared with the respective co-lender.
    </div>
  )
}

            
          </div>
          <br />
          <br />
          <br />

          <div>Warm Regards</div>

          <div>Kaabil Finance Private Limited</div>
          <br />
          <br />

          <p style={{ fontSize: "16px", marginTop: "250px" }}>
            “This document is computer generated and does not require the
            Registrar's signature or the Company's stamp in order to be
            considered valid”
          </p>
          <img
            src={kaabilfooter}
            style={{
              position: "absolute",
              width: "300mm",
              marginTop: "150px",
            }}
            alt="KFPL Footer"
          />
        </div>
      </div>
    </>
  );
};

export default NocLetter;
