import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { letter } from "../../../_services/lettertracking.service";
import logo from "../../../assets/kaabil.png"
import signature from "../../../assets/kaabil_footer2.png"
import { useReactToPrint } from "react-to-print";
import './Settlememt.css'
function SettlementLetterPrint() {


    const LegalLetterRef = React.useRef();

    const handleSubmissionPrint = useReactToPrint({
      content: () => LegalLetterRef.current,
      pageStyle: "print"
    });

    
    const [formData, setFormData] = useState("")
    const { id } = useParams();
    const getSingleSettlement = async () => {
        const details = await letter.getSingleSettlement(id);
        setFormData(details)
        console.log(details, "ssssssssssssswwwwwwwwwww");

    }

    useEffect(() => {
        if (id) {
            getSingleSettlement()
        }
    }, [])
    const numberToWords = (num) => {
        const units = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
        const teens = ['ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
        const tens = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
    
        if (num === 0) return 'zero';
    
        let words = '';
    
        if (num > 9999999) {
            words += numberToWords(Math.floor(num / 10000000)) + ' crore ';
            num %= 10000000;
        }
    
        if (num > 99999) {
            words += numberToWords(Math.floor(num / 100000)) + ' lakh ';
            num %= 100000;
        }
    
        if (num > 999) {
            words += numberToWords(Math.floor(num / 1000)) + ' thousand ';
            num %= 1000;
        }
    
        if (num > 99) {
            words += units[Math.floor(num / 100)] + ' hundred ';
            num %= 100;
        }
    
        if (num > 19) {
            words += tens[Math.floor(num / 10)] + ' ';
            num %= 10;
        }
    
        if (num > 0) {
            words += (num < 10 ? units[num] : teens[num - 10]);
        }
    
        return words.trim();
    };

    const wordAmount = numberToWords(formData?.deposite_amount);

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // January is 0, so we add 1
    const date = currentDate.getDate();
    return (
        <div className='page' style={{ marginLeft: "20px", marginRight: "20px", maxHeight: "fit-content" }}>
        <div style={{ display:"flex",justifyContent:"center"}}>
          <button onClick={handleSubmissionPrint} class="settlementbutton" type="button">
  <span class="settlementbutton__text">Print Settlement Certificate</span>
  <span class="settlementbutton__icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35" id="bdd05811-e15d-428c-bb53-8661459f9307" data-name="Layer 2" class="svg"><path d="M17.5,22.131a1.249,1.249,0,0,1-1.25-1.25V2.187a1.25,1.25,0,0,1,2.5,0V20.881A1.25,1.25,0,0,1,17.5,22.131Z"></path><path d="M17.5,22.693a3.189,3.189,0,0,1-2.262-.936L8.487,15.006a1.249,1.249,0,0,1,1.767-1.767l6.751,6.751a.7.7,0,0,0,.99,0l6.751-6.751a1.25,1.25,0,0,1,1.768,1.767l-6.752,6.751A3.191,3.191,0,0,1,17.5,22.693Z"></path><path d="M31.436,34.063H3.564A3.318,3.318,0,0,1,.25,30.749V22.011a1.25,1.25,0,0,1,2.5,0v8.738a.815.815,0,0,0,.814.814H31.436a.815.815,0,0,0,.814-.814V22.011a1.25,1.25,0,1,1,2.5,0v8.738A3.318,3.318,0,0,1,31.436,34.063Z"></path></svg></span>
</button>
        </div>
        <link rel='stylesheet' media='print' href='/LegalLetter.css' />
            <div ref={LegalLetterRef} className='letter' style={{ fontSize: '23px' }}>

                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <img src={logo} alt="KFPL Logo" style={{ width: "40vw", float: "left" }} />
                    <div style={{ float: "right", width: "40%", display: "grid" }}>
                        <span><b>Registered Office</b> : 328 C, Kusum Vihar, Ramnagaria Road, Jagatpura, Jaipur, Rajasthan 302025</span>
                        <span><b>Corporate Office</b> : 405,408,SD 157, Mile Stone, Tonk Road, Jaipur, Rajasthan 302015</span>
                    </div>
                </div>
                <br /> <br /> <br />


                <h2 style={{ margin: "auto", borderBottom: "5px solid", width: "30%" }}>Settlement Certificate</h2>
                <div className='start' style={{ marginTop: "85px" }} >
                    <span >Dated:-{date}/{month}/{year}</span>     <span>Letter Id:-KFPL/{year}{month}/{date}{month}</span>

                </div>
               <br />

                <br />

                <div className='content'>
                    <b>{formData?.name}</b> {formData?.relation} <b>{formData?.father_name}</b> (Applicant)
                </div>

              <div className='content'>
                    Addres:- {formData?.address}
                </div>
                <br />
                <div style={{ textAlign: "justify" }}>
                    <div className='content' style={{ marginTop: "5px" }}>
                        <b> SUB : Settlement Certificate Secured Business Loan A/C No. {formData?.account_number} Rs {formData?.deposite_amount}/-</b>
                    </div>
                    <br />

                    <div className='content' style={{ marginTop: "5px" }}>
                        Dear Sir/Madam,
                    </div>
                    <div className='content' style={{ marginTop: "5px" }}>
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; This is to certify that <b>{formData?.name}</b> {formData?.relation} <b>{formData?.father_name}</b> maintaining loan account no. <b>{formData?.account_number}</b> amount Rs <b>{formData?.deposite_amount}/-</b> (<b>Rupees {wordAmount} only</b>) With Kaabil Finance Private Limited. The Loan has been settled under one time settlement scheme of the company.
                    </div>
                    <br /> <br /> <br /> <br />
                    <div className='content'>
                        Warm Regards
                    </div>
                    <div className='content'>
                        Kaabil Finance Private Limited
                    </div>
                </div>
                <br />


                <img
                    src={signature}
                    style={{
                        position: "absolute",
                        width: "100%",
                        top: "345mm"
                    }}
                    alt="KFPL Footer"
                />
            </div>
        </div>
    )
}

export default SettlementLetterPrint