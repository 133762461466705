import React from 'react'
import { Route, Switch } from "react-router-dom";
import CamLetterList from './CamLetterList';
import ForClouserLetterList from './ForCloserLetterList';
import LODLetterList from './LODLetterList';
import NocLetterList from './NocLetterList';
import ScLetterList from './ScLetterList'
import SanctionLetterList from './SanctionLetterList'
import DisbursementList from './DisbursementList';

const Index = (match) => {

    const {path} = match;

  return (
    <>
    <Switch>
     <Route path={`/home/letterlist/doc`} component={LODLetterList} /> 
     <Route path={`/home/letterlist/noc`} component={NocLetterList} />
     <Route path={`/home/letterlist/for`} component={ForClouserLetterList} />
     <Route path={`/home/letterlist/sc`} component={ScLetterList} />
     <Route path={`/home/letterlist/sanlist`} component={SanctionLetterList}/>
     <Route path={`/home/letterlist/camlist`} component={CamLetterList}/>
     <Route path={`/home/letterlist/disbursementlist`} component={DisbursementList}/>
   </Switch>
   </>
  )
}

export default Index