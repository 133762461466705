import { CreditSubStages } from "../constants/creditsubstages";
import { OperationSubStages } from "../constants/operationsubstages";
import datehelper from "./datehelper";
import {customerService} from "../_services"
const branchesWithExpressLoans = ['CG15', 'BR20', 'BG17', 'GP21', 'AJ13', 'BM18', 'BW14', 'SR22']
const branchesWithPartner = ['UN23']

const isLoginFeesReceived = (loanObj) => {
    //return true;

    if (loanObj && loanObj.application_fees && loanObj.application_fees.payment_amount > 0) {
        return true;
    }
    return false;

}

const isEMDReceived = (loanObj) => {
    console.log("loan obj emd ",loanObj)
    //return true;  
    const createdDate = new Date(loanObj.created);
 
    // Convert to Indian Standard Time (IST)
    const ISTDateString = createdDate.toLocaleString('en-US', {timeZone: 'Asia/Kolkata'});
    const ISTDate = new Date(ISTDateString);
    // Check if the date is in February 2024 and is the 20th or 21st in Indian time
    const indianYear = ISTDate.getFullYear();
    const indianMonth = ISTDate.getMonth() + 1; // Month is zero-based, so add 1
    const indianDate = ISTDate.getDate();
    console.log('createdDate:', ISTDate);
    console.log('Year:', indianYear);
    console.log('Month:', indianMonth);
    console.log('Date:', indianDate);
    if ((indianYear === 2024 && indianMonth === 2 && (indianDate === 20 || indianDate === 21)) ||( indianYear === 2024 && indianMonth === 3 && (indianDate === 4 || indianDate === 5 || indianDate===11 || indianDate===12 || indianDate===18 || indianDate===19 || indianDate===23 || indianDate===24 || indianDate===26) && loanObj && loanObj.application_fees && loanObj.application_fees.payment_amount == 1201)) {
        return true;
    }

    if (loanObj && loanObj.emd_fees && loanObj.emd_fees.payment_amount > 1200) {
        return true;
    }
    if (loanObj && loanObj.application_fees && (loanObj.application_fees.payment_amount == 2500 || loanObj.application_fees.payment_amount == 1770)) {
        return true;
    }
    if (loanObj && loanObj.application_fees && loanObj.application_fees.payment_amount == 3000) {
        return true;
    }
    return false;

}

const isPDVisited = (loanObj) => {
    if (loanObj && loanObj.pd_details && loanObj.pd_details.pd_status) {
        return true;
    }
    return false;
}

const isFileReceivedInHO = (loanObj) => {
    let fileReceived = false;
    if (loanObj && loanObj.stage_steps) {
        console.log(loanObj.stage_steps);
        loanObj.stage_steps.map(ss => {
            if (ss.stage_step_name === OperationSubStages.FileReceived) {
                fileReceived = true;
            }
        });
    }
    else {
        console.log("stage steps is NULL");
    }
    return fileReceived;
}

const isKYCVerified = (loanObj) => {
    if(!isKarzaVerified(loanObj))
    {
        return false;
    }
    let isKYCVerified = false;
    if (loanObj && loanObj.stage_steps) {
        console.log(loanObj.stage_steps);
        loanObj.stage_steps.map(ss => {
            if (ss.stage_step_name === CreditSubStages.KYCPending) {
                isKYCVerified = true;
            }
        });
    }
    else {
        console.log("stage steps is NULL");
    }
    return isKYCVerified;
}

const isKarzaVerified = (loanObj) => {
    if(!loanObj)
    {
        return false;
    }
    let isKarzaVerified = false;
    if(loanObj.applicant && loanObj.applicant.general_information && loanObj.applicant.general_information.karza_aadhar_verified)
    {
        return true;
    }
    if(loanObj.co_applicants)
    {
        loanObj.co_applicants.map((ca)=> {
            if(ca && ca.general_information && ca.general_information.karza_aadhar_verified)
            {
                isKarzaVerified=true;
                return true;
            }
        })
    }
    if(loanObj.guarantors)
    {
        loanObj.guarantors.map((ga)=> {
            if(ga && ga.general_information && ga.general_information.karza_aadhar_verified)
            {
                isKarzaVerified=true;
                return true;
            }
        })
    }

    return isKarzaVerified;
}



const isOTPVerified = (loanObj) => {
    let isOTPVerified = false;
    if (loanObj && loanObj.applicant && loanObj.personal_details && loanObj.personal_details.otp_verified) {
        isOTPVerified = true;
    }
    return isOTPVerified;
}

const isApplicationFormDone = (loanObj) => {
    let isApplicationFormDone = false;
    let applicant_count = 1;
    if (loanObj && loanObj.co_applicants) {
        applicant_count = applicant_count + loanObj.co_applicants.length;
    }
    if (loanObj && loanObj.guarantors) {
        applicant_count = applicant_count + loanObj.guarantors.length;
    }

    if (loanObj && loanObj.application_form && loanObj.application_form.length >= applicant_count) {
        isApplicationFormDone = true;
    }
    return isApplicationFormDone;
}


const isCamLetterDone = (loanObj) => {
    let isCAMLetterDone = false;

    if (loanObj && loanObj.cam_letters && loanObj.cam_letters.length > 0) {
        isCAMLetterDone = true;
    }
    return isCAMLetterDone;
}

const isCibilChecked = (loanObj) => {
    let isCibilChecked = false;
    if(!loanObj || !loanObj.cibil_requests || loanObj.cibil_requests.length==0)
    {
        return false;
    }

    loanObj.cibil_requests.map((cr)=> {
        if(cr && cr.report && cr.report !="")
        {
             isCibilChecked=true;
             return true;
        }
    })
    
    return isCibilChecked;
}


const isLegalValuationDone = (loanObj) => {
    if(!loanObj || !loanObj.valuation_report_verify_details || !loanObj.valuation_report_verify_details.is_verified)
    {
        return false;
    }
    
    return true;
}


const isColenderPaymentReceived = (loanObj) => {
    let isPaymentReceived = false;
    if (loanObj && loanObj.co_lending && loanObj.co_lending.length > 0) {
        loanObj.co_lending.map((li) => {
            if (li.co_lender_status === "AmountReceivedFromColender") {
                isPaymentReceived = true;
            }
        })
    }
    return isPaymentReceived;
}

const isInLOS = (loanObj) => {
    if (loanObj && ["Sales", "Credit", "Sanction", "Operation", "Disbursal"].includes(loanObj.stage)) {
        return true;
    }
    return false;
}

const isInLMS = (loanObj) => {
    if (loanObj && ["Collection", "Closed"].includes(loanObj.stage)) {
        return true;
    }
    return false;
}

const isNotTracked = (loanObj) => {
    if (loanObj && ["Discarded", "Suspense", "Rejected", "Cancelled"].includes(loanObj.stage)) {
        return true;
    }
    return false;
}

const isOneFemaleCoApplicant = (loanObj) => {
    let femaleCoApp = false
    if(loanObj && loanObj.applicant && loanObj.applicant.general_information && loanObj.applicant.general_information.gender && loanObj.applicant.general_information.gender==="Female")
    {
        return true;
    }
    loanObj && loanObj.co_applicants && loanObj.co_applicants.length>0 && loanObj.co_applicants.map((x) => {
        if (x.general_information.title === "Mrs" || x.general_information.title === "Mrs." || x.general_information.gender === 'Female') {
            femaleCoApp= true
        }
    })
   return femaleCoApp;
}

const isOneCoApplicant = (loanObj) => {
    if (loanObj && loanObj.co_applicants && loanObj.co_applicants.length > 0) {
        return true
    }
    return false;

}
const isDiscardAllowed = (loanObj) => {
    if (!loanObj) {
        return false;
    }
    let loginDate = new Date(loanObj.created);
    let todayDate = new Date()
    let daysDiff = datehelper.getDateDiffDays(todayDate, loginDate);
    console.log("Days since Login " + daysDiff);
    if (daysDiff > 3) {
        return false;
    }

    return true;
}

const isReferenceAdded = (loanObj) => {
    if (!loanObj) {
        return false;
    }
    if(loanObj && loanObj.reference && loanObj.reference.length>0){
        return true
    }else{
        return false
    }
}

const isOneGuarantor = (loanObj) => {
    if (loanObj && loanObj.guarantors && loanObj.guarantors.length > 0) {
         return true
    }
    return false;
}

const isOneAdharVerified = (loanObj) => {
    if ((loanObj && loanObj.applicant && loanObj.applicant.general_information && loanObj.applicant.general_information.karza_aadhar_verified)
        || (loanObj && loanObj.co_applicants && loanObj.co_applicants.some(x => x.general_information.karza_aadhar_verified))
        || (loanObj && loanObj.guarantors && loanObj.guarantors.some(x => x.general_information.karza_aadhar_verified))) {
        return true;
    }
}
const isOneMobileNoVerified = (loanObj) => {
    if ((loanObj && loanObj.applicant && loanObj.personal_details && loanObj.personal_details.otp_verified))
    {
        return true;
    }
}
const isApplicantKyc = (loanObj) => {
    if (loanObj && loanObj.applicant && loanObj.applicant.kyc_images && loanObj.applicant.kyc_images.length > 0) {
        return true;
    }
}
// {'customer_photo':{'title':'Customer Photo','tag':'Customer Photo'},
// 'aadhar_front': {'title':'Aadhar','tag':'Aadhar Front Side'},
// 'aadhar_back': {'title':'Aadhar','tag':'Aadhar Back Side'},
// 'pan':{ 'title':'PAN Card','tag':'PAN Card'},
// 'voter_id':{ 'title':'Voter Id','tag':'Voter Id'},
// 'driving_licence':{'title':'Driving Licence','tag':'Driving License'},
// 'other':{'title':'Other','tag':'Other Id'}
const isApplicantTwoKycImageDone = (loanObj) => {
    if (loanObj && loanObj.applicant && loanObj.applicant.kyc_images) {
        const kycImages = loanObj.applicant.kyc_images;
        
        let kycCount = 0;

        kycImages.forEach(image => {
            if (/aadhar.*front.*side/i.test(image.tag)) {
                kycCount++;
            } else if (/pan.*card/i.test(image.tag)) {
                kycCount++;
            } else if (/voter.*id/i.test(image.tag)) {
                kycCount++;
            }
        });
        
        console.log('kycCount', kycCount);
        
        return kycCount >= 2;
    }
    
    return false;  
};   
                               

const isCoapplicantTwoKycImageDone = (loanObj) => {
    if (loanObj && loanObj.co_applicants) {
        return loanObj.co_applicants.some(data => {
            const kycImages = data.kyc_images;
            let kycCount = 0;
            kycImages.forEach(image => {
                if (/aadhar.*front.*side/i.test(image.tag)) {
                    kycCount++;
                } else if (/pan.*card/i.test(image.tag)) {
                    kycCount++;
                } else if (/voter.*id/i.test(image.tag)) {
                    kycCount++;
                }
            });
            
             console.log('co_applicant kycCount', kycCount);
            if (kycCount >= 2) {
                return true;
            }
        });
    }
    return false;
};
                                 
   

const isOneCoApplicantKyc = (loanObj) => {
    if (loanObj && loanObj.co_applicants && loanObj.co_applicants.some(x => x.kyc_images && x.kyc_images.length > 0)) {
        return true;
    }
}
 
const isTwoKycDoneForApplicant =   (loanObj) => {
    
        
        
         let count = 0;
        if (loanObj && loanObj.applicant && loanObj.applicant.general_information
            ) {
            if (loanObj.applicant.general_information.aadhar) count++;
            if (loanObj.applicant.general_information.pan_no) count++;
            if (loanObj.applicant.general_information.voter_id) count++;
        }
        console.log("applicant kyc", count);
        return count >= 2;
    } 
 



    const isTwoKycDoneForcoapplicant = (loanObj) => {
        console.log("two kyc isTwoKycDoneForcoapplicant is minimum", loanObj);
        if (loanObj && loanObj.co_applicants) {
            for (const val of loanObj.co_applicants) {
                let count = 0;
                if (val.general_information.aadhar) count++;
                if (val.general_information.pan_no) count++;
                if (val.general_information.voter_id) count++;
                console.log("co-applicant kyc", count);
                if (count >= 2) {
                    return true;
                }
            }
        }
        return false;
    };
    

const isCibilDone = (loanObj) => {
    if ((loanObj && loanObj.cibil_requests && loanObj.cibil_requests.length === loanObj.co_applicants.length + loanObj.guarantors.length+1) && (loanObj.cibil_requests.every((x) => x.report !== ''))) {
        return true;
    }
}

const isImdFeesCollected = (loanObj) => {
    if (loanObj && loanObj.emd_fees !== null) {
        return true;
    }
}
const isCersiReport = (loanObj) => {
    if (loanObj && loanObj.property_details && loanObj.property_details.cersai_reports && loanObj.property_details.cersai_reports.every((x) => x.status === "approved")) {
        return true;
    }
}
const isCamLetterGenerated = (loanObj) => {
    if (loanObj && loanObj.cam_letters && loanObj.cam_letters.length > 0) {
        return true;
    }
}
const isApplicationFormCompleted = (loanObj) => {
    if (loanObj && loanObj.co_applicants && loanObj.co_applicants.length + loanObj && loanObj.guarantors && loanObj.guarantors.length === loanObj.application_form && loanObj.application_form.length) {
        return true;
    }
}
const isValuationReportGenerated = (loanObj) => {
    if (loanObj && loanObj.valuation_report && loanObj.valuation_report) {
        return true;
    }
}
const isBmlevelvarification =(loanObj)=>{
    if(loanObj && loanObj.validation_from_bm && loanObj.validation_from_bm.loan_amount && loanObj.validation_from_bm.tenure   && loanObj.validation_from_bm.income && loanObj.validation_from_bm.property_value ){
        return true;
    }
    return false;
}

const isColenderAdded=(loanObj)=>{
    let flag = false;
    console.log('loanObj is loanObj',loanObj)
     if(loanObj && loanObj.co_lending && loanObj.co_lending.length>0){
        loanObj.co_lending.map((val,ind)=>{
            console.log('val si',val.co_lender_status)
            if(val && val.co_lender_status && val.co_lender_status!="ColendingCancelled"){
               flag=true;
            }
        })
    }
    return flag;
}

const workflowHelper = {
    isLoginFeesReceived,
    isPDVisited,
    isFileReceivedInHO,
    isKYCVerified,
    isColenderPaymentReceived,
    isOTPVerified,
    isEMDReceived,
    isApplicationFormDone,
    isCamLetterDone,
    isInLOS,
    isInLMS,
    isNotTracked,
    isOneFemaleCoApplicant,
    isOneCoApplicant,
    isDiscardAllowed,
    isKarzaVerified,
    isReferenceAdded,
    isOneGuarantor,
    isCibilChecked,
    isOneAdharVerified,
    isOneMobileNoVerified,
    isApplicantKyc,
    isOneCoApplicantKyc,
    isCibilDone,
    isImdFeesCollected,
    isCersiReport,
    isCamLetterGenerated,
    isApplicationFormCompleted,
    isValuationReportGenerated,
    isLegalValuationDone,
    isBmlevelvarification,
    isTwoKycDoneForApplicant,
    isTwoKycDoneForcoapplicant,
    isApplicantTwoKycImageDone,
    isCoapplicantTwoKycImageDone,
    isColenderAdded
}
 
export default workflowHelper