import React from "react";
import "./sanction.css";
import logo from "../../../assets/kaabil.png";
import capitalfinance from "../../../assets/capitalfinance.png";
import capital from "../../../assets/capital.png";
import ugroFooter from "../../../assets/kaabilfooter_ugro.png";
import ugroLogo from "../../../assets/ugrow_capital.png";
import bfplLogo from "../../../assets/bfpl_logo.png";
import vistaarLogo from "../../../assets/Vistaar.jpg"
import capitalFooter from "../../../assets/kaabil_footer.png";
import kaabilFooter from "../../../assets/kaabil_footer_capital_india.png";
import KaabilOfficeFooter from "../../../assets/KaabilFooter.png"
import { misService } from "../../../_services";
import bhalaFooter from "../../../assets/kaabilfooter_bhala.png";
import { virtualAccountService } from "../../../_services/virtualaccount.service";
import nachform from "../../../assets/nachform.png";
import vistaarFooter from "../../../assets/kaabilfooter_vistaar.png"
import datehelper from "../../../_helpers/datehelper";
const footerDiv = (bank) => (
  <div>
    <img
      src={KaabilOfficeFooter}
      style={{
        position: "absolute",
        width: "270mm",
        top: "380mm"
      }}
      alt="KFPL Footer"
    />
    {bank === "Capital" && (
      <img
        src={kaabilFooter}
        style={{
          position: "absolute",
          width: "270mm",
         top: "370mm"
        }}
        alt="KFPL Footer"
      />
    )}
    {bank === "ugro" && (
      <img
        src={ugroFooter}
        style={{
          position: "absolute",
          width: "270mm",
           top: "370mm"
        }}
        alt="KFPL Footer"
      />
    )}
    {bank === "bfpl" && (
      <img
        src={bhalaFooter}
        style={{
          position: "absolute",
          width: "270mm",
          top: "370mm"
        }}
        alt="KFPL Footer"
      />
    )}
    {bank === "vistaar" && (
      <img
        src={vistaarFooter}
        style={{
          position: "absolute",
          width: "270mm",
          top: "370mm"
        }}
        alt="KFPL Footer"
      />
    )}
    {bank === "home" && (
      <img
        src={capitalFooter}
        style={{
          position: "absolute",
          width: "270mm",
          top: "370mm"
        }}
        alt="Capital Home Footer"
      />
    )}
  </div>
);

const headerDiv = (bank) => (
  <div  >
    <img src={logo} style={{ height: "100px" }} alt="KFPL Logo" />
    {bank === "Capital" && (
      <img
        src={capitalfinance}
        style={{ height: "100px", position: "absolute", top: "2", right: "0",marginRight:"3rem" }}
        alt="Capital Finance Logo"
      />
    )}
    {bank === "ugro" && (
      <img
        src={ugroLogo}
        style={{ height: "100px", position: "absolute", top: "2", right: "0",marginRight:"3rem" }}
        alt="Ugrow Capital Logo"
      />
    )}
    {bank === "bfpl" && (
      <img src={bfplLogo} style={{ height: "100px", position: "absolute", top: "2", right: "0",marginRight:"3rem" }} alt="Bfpl Capital Logo" />
    )}
    {bank === "vistaar" && (
      <img src={vistaarLogo} style={{ height: "100px", position: "absolute", top: "2", right: "0",marginRight:"3rem" }} alt="Vistaar Finance Logo" />
    )}
    {bank === "home" && (
      <img
        src={capital}
        style={{ height: "100px", position: "absolute", top: "2", right: "0",marginRight:"3rem" }}
        alt="Capital India Home Logo"
      />
    )}
  </div>
);

export default class KeyFactStatement extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loanno: "", vaDetails: null };
  }

  getEMI(p, r, n) {
    var rate = r / 1200;
    console.log(p, rate, n);
    var numerator = p * rate * Math.pow(1 + rate, n);
    var denominator = Math.pow(1 + rate, n) - 1;

    return numerator / denominator;
  }

  nth = function (d) {
    if (d > 3 && d < 21) return "th";
    switch (d % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };



  
  componentDidMount() {
    misService.getLoanNo("SBL").then((result) => {
      this.setState({
        loanno:
          result.loan_no > 999
            ? result.loan_no.toString()
            : result.loan_no.toString().padStart(3, "0"),
      });
    });

    let fields = {
      loan_account: this.props.dataFill.application_id,
    };
    virtualAccountService
      .getByLoanAccount(fields)
      .then((va) => {
        console.log("got va details");
        console.log(va);
        this.setState({ vaDetails: va });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  render() {
    const data = this.props.dataFill;
    console.log("egrhty", data)
    
    console.log("Data received:", data);
    
    // Make sure data.createdAt is available before proceeding
    if (!data || !data.createdAt) {
      return <h2>No date available</h2>;
    }

    // Perform EMI date calculation
    const today = new Date(data.createdAt);

    let targetMonth, targetYear;

    if (today.getDate() <= 5) {
      targetMonth = today.getMonth() + 1; // Months are zero-based in JavaScript
      targetYear = today.getFullYear();
      if (targetMonth > 12) {
        targetMonth = 1;
        targetYear += 1;
      }
    } else {
      targetMonth = today.getMonth() + 2;
      targetYear = today.getFullYear();
      if (targetMonth > 12) {
        targetMonth -= 12;
        targetYear += 1;
      }
    }

    // Calculate the 5th day of the target month
    const emiDate = new Date(targetYear, targetMonth - 1, 5); // Month index is zero-based in JavaScript

    // Format the date to dd-mm-yyyy
    const formattedEmiDate = `${('0' + emiDate.getDate()).slice(-2)}-${('0' + (emiDate.getMonth() + 1)).slice(-2)}-${emiDate.getFullYear()}`;

    console.log("EMI Date:", formattedEmiDate);

    var emi_table = [];
    // var tenure_per_page = Math.ceil( data.tenure/25)

    var emi = this.getEMI(
      data.sanction_amount,
      data.interest_rate,
      data.tenure
    );
    var first_interest = (data.interest_rate / 1200) * data.sanction_amount;
    emi_table.push({
      start_balance: data.sanction_amount,
      date: new Date(
        new Date(data.start_date).setMonth(
          new Date(data.start_date).getMonth() - 1
        )
      ),
      interest_paid: first_interest,
      payment_amount: emi,
      capital_paid: emi - first_interest,
      balance_remaining: data.sanction_amount - (emi - first_interest),
    });

    for (var i = 1; i <= data.tenure; i++) {
      var current_interest =
        (data.interest_rate / 1200) * emi_table[i - 1].balance_remaining;
      emi_table.push({
        start_balance: emi_table[i - 1].balance_remaining,
        date: new Date(
          emi_table[i - 1].date.setMonth(emi_table[i - 1].date.getMonth() + 1)
        ),
        payment_amount: emi,
        interest_paid: current_interest,
        capital_paid: emi - current_interest,
        balance_remaining:
          emi_table[i - 1].balance_remaining - (emi - current_interest),
      });
    }

    const date = new Date();
    let sanctionAmount = Number(data.sanction_amount); // Get the sanction amount
    let processingFeess = (Number(data.customer[0].loan.processing_fees) + (Number(data.customer[0].loan.processing_fees) * 0.18) + 2500).toFixed(2) // Get the processing fees
    
    // Calculation for the subtraction
    let feesWithTaxAndAdditionals = (Number(data.sanction_amount) * 0.06) +1180 +2360; // Processing fees + 18% tax + 2500
   


    let pd_date = new Date(data.pd_date);
    pd_date = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
    const paymentDivs = (paymentData) => {
      let divs = [];
      let i = 0;

      while (i < paymentData.length) {
        divs.push(
          <div
            style={{
              width: "290mm",
              height: "410mm",
              backgroundColor: "white",
              padding: 40,
              position: "relative",
            }}
          >
            {headerDiv(data.bank)}
            <br />
            <p>
            
            <h4 style={{textAlign:"center",fontWeight:"bold"}}>Illustrative Repayment Schedule under Equated Periodic Instalment for the hypothetical loan illustrated in Annexure C</h4>
              <table class="stretch">
                <tr style={{textAlign:"center",verticalAlign:"middle"}}>
                  <th>Instalment Number</th>
                  <th>Instalment Date</th>
                  <th>Start Balance</th>
                  <th>Payment Amount</th>
                  <th>Capital Paid</th>
                  <th>Interest Paid</th>
                  <th>Remaining Balance</th>
                </tr>
                {console.log(paymentData,"paymentDatapaymentDatapaymentData")}
                {paymentData.slice(i, i + 48).map(
                  (item, idx) =>
                    idx + i !== data.tenure && (
                      <tr>
                        <td style={{ width: "10%" ,textAlign:'right'}}>{idx + i + 1}</td>{" "}
                        <td style={{textAlign:'right'}}>
  {" "}
  {item.date.getDate()}-
  {item.date.toLocaleString("default", {
    month: "2-digit", // This will give the two-digit number for the month, e.g., "09"
  })}
  -{item.date.getFullYear()}
</td>


                        <td style={{textAlign:'right'}}>{Math.round(item.start_balance)}</td>
                        <td style={{textAlign:'right'}}>{Math.round(item.payment_amount)}</td>
                        <td style={{textAlign:'right'}}> {Math.round(item.capital_paid)}</td>
                        <td style={{textAlign:'right'}}>{Math.round(item.interest_paid)}</td>
                        <td style={{textAlign:'right'}}>{Math.round(item.balance_remaining)}</td>
                      </tr>
                    )
                )}
              </table>
            </p>
            {footerDiv(data.bank)}
          </div>
        );
        i += 48;
      }

      return divs;
    };

    return (
      <div className="sanction">
        <style type="text/css" media="print">
          {"\
   @page { size: A4; }\
"}
        </style>
        {/* <br /> */}
        <div>
          <div
            style={{
              width: "290mm",
              height: "410mm",
              backgroundColor: "white",
              padding: 40,
              position: "relative",
            }}
          >
            {headerDiv(data.bank)}
     <>
     <h3 style={{textAlign:"center",fontWeight:"bold"}}>Annexure-A</h3>
     <h6 style={{textAlign:"center",fontWeight:"bold"}}>Key Fact Statement</h6>
     <h6 style={{textAlign:"center", marginTop:"2rem",fontWeight:"bold"}}>Part 1 (Interest rate and Fees/Charges)</h6>
      <table>
        <tbody>
        <tr>
            <th style={{textAlign:"center"}}>Sr.No.</th>
            <th style={{textAlign:"center"}}>Parameter</th>
            <th style={{textAlign:"center"}}>Details</th>
          </tr>
          <tr>
          <td>1</td>
            <td  className="tablebold">Lender</td>
            <td>Kaabil Finance Private Limited </td>
          </tr>
          <tr>
          <td>2</td>
            <td  className="tablebold">Loan Account No.</td>
            <td>{data?.application_id}</td>
          </tr>
          <tr>
          <td>3</td>
            <td  className="tablebold">Type of Loan</td>
            <td>Secured Business Loans</td>
          </tr>
          <tr>
          <td>4</td>
            <td  className="tablebold">Loan Amount Sanctioned</td>
            <td>INR. {new Intl.NumberFormat('en-IN').format(data?.sanction_amount)} /-</td>
          </tr>
          <tr>
          <td>5</td>
            <td  className="tablebold">Disbursal Schedule</td>
            <td>100% Upfront</td>
          </tr>
          <tr>
          <td>6</td>
            <td  className="tablebold">Term of Loan/Tenure(monthly/yearly/days)</td>
            <td>{data.tenure} Months</td>
          </tr>
          <tr>
          <td>7</td>
            <td  className="tablebold">Interest Rate applicable and type</td>
            <td>Interest @ {data?.interest_rate} % p.a. & Fixed</td>
          </tr>
          <tr>
          <td>8</td>
            <td  className="tablebold">Amount of each instalment</td>
            <td>INR .{" "}
            {new Intl.NumberFormat('en-IN').format(Math.round(
                    this.getEMI(
                      data?.sanction_amount,
                      data?.interest_rate,
                      data?.tenure
                    )
                  ))}
                  /-</td>
          </tr>
          <tr>
          <td>9</td>
            <td className="tablebold">Due date of payment of instalment</td>
            <td>{formattedEmiDate}</td>
          </tr>
          <tr>
          <td>10</td>
            <td className="tablebold">Total interest charged during the entire tenure of loan and rate of interest in Rupees</td>
            <td>INR. {new Intl.NumberFormat('en-IN').format((Math.round(emi) *data?.tenure) - data?.sanction_amount)} /- </td>
          </tr>
          {/* <tr>
          <td>11</td>
            <td className="tablebold">Insurance</td>
            <td>
              Insurance amount will be approximately 6% of Sanction amount
              however it may vary based on tenure and Age
            </td>
          </tr> */}
          <tr>
          <td>11</td>
            <td className="tablebold">Processing fee (non-refundable)</td>
            <td>INR. {new Intl.NumberFormat('en-IN').format((Number(data?.sanction_amount) * 0.03) +(Number(data?.sanction_amount) * 0.03) * 0.18)} /-</td>
          </tr>
         {/* <tr>
          <td>13</td>
            <td className="tablebold">Valuation Charges</td>
            <td>Rs.2000 + GST</td>
          </tr> */}
          <tr>
          <td>12</td>
            <td className="tablebold">Annual Percentage Rate</td>
            <td>{data.interest_rate}% P.a</td>
          </tr>
          <tr>
          <td>13</td>
            <td className="tablebold">Penal Charges</td>
            <td>INR. 2,000/- + GST</td>
          </tr>
          <tr>
          <td>14</td>
            <td className="tablebold">Foreclosure Charges</td>
            <td>Up to 10.0% + GST</td>
          </tr>
          {/* <tr>
          <td>17</td>
            <td className="tablebold">Pre-payment Charges</td>
            <td></td>
          </tr> */}
          <tr>
          <td>15</td>
            <td className="tablebold">Cheque Swapping charges</td>
            <td>INR. 1,000/- + GST</td>
          </tr>
          <tr>
          <td>16</td>
            <td className="tablebold">EPI Bouncing Charges</td>
            <td>INR. 1,000/- + GST</td>
          </tr>
          {/* <tr>
          <td>17</td>
            <td className="tablebold">Cersai Charge</td>
            <td>Rs.1000 + GST</td>
          </tr> */}
          <tr>
          <td>17</td>
            <td className="tablebold">Type of Installment</td>
            <td>Monthly</td>
          </tr>
          <tr>
          <td>18</td>
            <td className="tablebold">Number of EPI's (Months)</td>
            <td>{data.tenure} (Months)</td>
          </tr>
          <tr>
          <td>19</td>
            <td className="tablebold">Additional Repayment Schedule</td>
            <td>INR. 2,000/- + GST</td>
          </tr>
          <tr>
          <td>20</td>
            <td className="tablebold">Statement of Account (SOA) Charge</td>
            <td>INR. 2,000/- + GST</td>
          </tr>
          <tr>
          <td>21</td>
            <td className="tablebold">List of Documents (LOD) Issuance Charge</td>
            <td>INR. 2,000/- + GST</td>
          </tr>
          <tr>
          <td>22</td>
            <td className="tablebold">Partail Prepayment</td>
            <td>10% prepayment fee for partial prepayment. No partial prepayment allowed in loans less than 6 MOB. Partial prepayments allowed only 2 times in a year. There should be a minimum gap of 4 months between two partial prepayments</td>
          </tr>
          <tr>
          <td>23</td>
            <td className="tablebold">Swap Charges</td>
            <td>INR. 2,000/- per swap+ GST</td>
          </tr>
          <tr>
          <td>24</td>
            <td className="tablebold">Cheque/ECS/NACH Bounce Charges </td>
            <td>INR. 1,000 /- (Including GST per  presentation)</td>
          </tr>
          <tr>
          <td>25</td>
            <td className="tablebold">Late Payment Charges</td>
            <td>Charged upto 4.00 % p.m. on overdue/delays/defaults of any monies payable</td>
          </tr>
        </tbody>
      </table>
   </>
            {footerDiv(data.bank)}
          </div>
          <div
            style={{
              width: "290mm",
              height: "410mm",
              backgroundColor: "white",
              padding: 40,
              position: "relative",
            }}
          >
            {headerDiv(data.bank)}
            <div>
      
      <table border="1" cellPadding="10" cellSpacing="0">
        <thead>
          <tr style={{textAlign:"center"}}>
          <th  style={{textAlign:"center"}}>26</th>
            <th  style={{textAlign:"center"}} colSpan="5"> Fees/charges </th>
          </tr>
        </thead>
        <tbody> 
        <tr>
         
       
         <td  colspan="2"></td>
         <td  colspan="2">Payable to the RE (A)</td>
         <td  colspan="2">Payable to a third party through RE (B)</td>
       </tr>
          <tr>
         
            <td></td>
            <td ></td>
            <td >One-time/ Recurring</td>
            <td >Amount (in ₹) or Percentage (%) as applicable</td>
            <td >One- time/Recurring</td>
            <td >Amount (in ₹) or Percentage (%) as applicable</td>
          </tr>
          <tr>
          <td>(i)</td>
            <td>Processing fees</td>
            <td>One- Time</td>
            <td>INR. {new Intl.NumberFormat('en-IN').format((Number(data?.sanction_amount) * 0.03) +(Number(data?.sanction_amount) * 0.03) * 0.18)} /-</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
          <td>(ii)</td>
            <td>Insurance charges</td>
            <td>N/A</td>
            <td>N/A</td>
            <td>One- Time</td>
            <td>INR. {new Intl.NumberFormat('en-IN').format((Number(data?.sanction_amount) * 0.06))} /-</td>
          </tr>
          <tr>
          <td>(iii)</td>
            <td>Valuation fees</td>
            <td>N/A</td>
            <td>N/A</td>
            <td>One- Time</td>
            <td>INR. 2,000/- + GST</td>
            
          </tr>
          <tr>
          <td>(iv)</td>
            <td>CERSAI Charge</td>
            <td>N/A</td>
            <td>N/A</td>
            <td>One- Time</td>
            <td>INR. 1,000/- + GST</td>
            
          </tr>
          <tr>
          <td>(v)</td>
            <td>Any other (please specify)</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
       
          <tr>
          <td>A</td>
            <td>Initial Login Fee </td>
            <td>One- Time</td>
            <td>INR. 2,500/-</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
          <td>B</td>
            <td>Cheque Replenishment Charge/Administration Fee</td>
            <td>One- Time</td>
            <td>INR. 750/- + GST</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
          <td>C</td>
            <td>Document Retrieval Charges/ Balance Outstanding Letter</td>
            <td>One- Time</td>
            <td>INR. 5,000/- + GST</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
          <td>D</td>
            <td>IMD</td>
            <td>One- Time</td>
            <td>INR. 2,500/- </td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
        </tbody>
      </table>
    </div>
            {footerDiv(data.bank)}
          </div>
        </div>
        <div
            style={{
              width: "290mm",
              height: "410mm",
              backgroundColor: "white",
              padding: 40,
              position: "relative",
            }}
          >
            <br />
            {headerDiv(data.bank)}
            <br />
          
     
      <div className="container">
      <h6 style={{textAlign:"center",fontWeight:"bold"}}>Other Qualitative Information (Part 2)</h6>
      <table>
        
        <tbody>
          <tr>
            <td>1</td>
            <td>Clause of Loan agreement relating to engagement of recovery agents</td>
            <td colspan="3">Please refer <b>'Article 11'</b> in <b>'Secured Business Loan Agreement'</b></td>
          </tr>
          <tr>
            <td>2</td>
            <td>Clause of Loan agreement which details grievance redressal mechanism</td>
            <td colspan="3">Please refer <b>'Clause 6.1(j)'</b> in <b>'Secured Business Loan Agreement'</b></td>
          </tr>
          <tr>
            <td>3</td>
            <td>Phone number and email id of the nodal grievance redressal officer</td>
            <td colspan="3">
              0141-4005012 / <a href="mailto:care@kaabilfinance.in">care@kaabilfinance.in</a>
            </td>
          </tr>
          <tr>
            <td>4</td>
            <td>
              Whether the loan is, or in future maybe, subject to transfer to other REs or securitisation (Yes/No)
            </td>
            <td colspan="3">Yes</td>
          </tr>
          <tr>
            <td>5</td>
            <td  colspan="2">
              In case of lending under collaborative lending arrangements (e.g., co-lending/outsourcing), 
              following additional details may be furnished:
            </td>
            <td >
            Percentage (%)
            </td>
            <td >
            Signature of BCM/BM
            </td>
          </tr>
          <tr>
            <td></td>
            <td>Name of the originating RE, along with its funding proportion <sup>*1</sup></td>
            <td>KAABIL FINANCE Private Limited</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td>Name of the partner RE, along with its proportion of funding <sup>*2</sup></td>
            <td>Ugro /<br/>Vistar /<br/>
            CIFL /<br/>
             CIHL</td>
             <td></td>
             <td></td>
          </tr>
          <tr>
            <td></td>
            <td>Blended rate of interest</td>
            <td colspan="3">{data.interest_rate}% p.a.</td>
          </tr>
          <tr>
            <td>6</td>
            <td  colspan="4">In case of digital loans, following specific disclosures may be furnished:</td>
          </tr>
          <tr>
            <td></td>
            <td>i. Cooling off/lock-up period, during which borrower shall not be charged any penalty on prepayment of loan</td>
            <td colspan="3">N/A</td>
          </tr>
          <tr>
            <td></td>
            <td>ii. Details of LSP acting as recovery agent and authorized to approach the borrower</td>
            <td colspan="3">N/A</td>
          </tr>
        </tbody>
      </table>
      <p style={{marginTop:"2rem"}}>1* As Per the Sanction Letter Of KAABIL FINANCE Private Limited</p>
      <p>2* As Per the Sanction Letter Of colender/_____________________(Name of colender)             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;        <sub>(Signature of BCM/BM)</sub></p>
    </div>

            <br />
            {footerDiv(data.bank)}
          </div>
          <div
            style={{
              width: "290mm",
              height: "410mm",
              backgroundColor: "white",
              padding: 40,
              position: "relative",
            }}
          >
            <br />
            {headerDiv(data.bank)}
            <br />
          
     
      <div className="container">
      <h4>Annexure-B</h4>
      <h4 style={{textAlign:"center",fontWeight:"bold"}}>Illustration for computation of APR for Retail and MSME loans</h4>
      <table>
        
        <tbody>
          <tr>
            <th style={{textAlign:"center"}}>Sr.No.</th>
            <th style={{textAlign:"center"}}>Parameter</th>
            <th style={{textAlign:"center"}}>Details</th>
          </tr>
          <tr>
            <td>1</td>
            <td>Sanctioned Loan amount (in Rupees) (Annexure-A Sr.No. 4 of the KFS template – Part 1)</td>
            <td>INR. {new Intl.NumberFormat('en-IN').format(data?.sanction_amount)}/-</td>
          </tr>
          <tr>
            <td>2</td>
            <td>Loan Term (in years/ months/ days) (Annexure-A Sr.No. 6 of the KFS template – Part 1)</td>
            <td>
            {data?.tenure} (Months)
            </td>
          </tr>
          <tr>
            <td>A</td>
            <td>No. of instalments for payment of principal, in case of non- equated periodic loans</td>
            <td>
            {data?.tenure} (Months)
            </td>
          </tr>
          <tr>
            <td>B</td>
            <td>Type of EPI<br/>
                Amount of each EPI (in Rupees) <br/>
                nos. of EPIs (e.g., no. of EMIs in case of monthly instalments)
                (Annexure-A Sr.No. 18 of the KFS template – Part 1)
            </td>
            <td>
                Monthly<br/>
                INR. {new Intl.NumberFormat('en-IN').format(Math.round(emi))}/-<br/>
                {data?.tenure} (Months)
            </td>
          </tr>
          <tr>
            <td>C</td>
            <td>No. of instalments for payment of capitalised interest, if any</td>
            <td>
            INR. {new Intl.NumberFormat('en-IN').format(Math.round(emi))}/-
            </td>
          </tr>
          <tr>
            <td>D</td>
            <td>Commencement of repayments, post sanction (Annexure-A Sr.No. 9 of the KFS template – Part 1)</td>
            <td>
            {formattedEmiDate}
            </td>
          </tr>
          <tr>
            <td>3</td>
            <td>Interest rate type (fixed or floating or hybrid) (Annexure-A Sr.No. 7 of the KFS template – Part 1)</td>
            <td>
              Fixed Rate
            </td>
          </tr>
          <tr>
            <td>4</td>
            <td>Rate of Interest (Annexure-A Sr.No. 7 of the KFS template – Part 1)
            </td>
            <td>{data?.interest_rate}% </td>
          </tr>
          <tr>
            <td>5</td>
            <td >
            Total Interest Amount to be charged during the entire tenor of the loan as per the rate prevailing on sanction date (in Rupees)
            </td>
            <td>  INR. {new Intl.NumberFormat('en-IN').format((Math.round(emi) *data?.tenure) - data?.sanction_amount)}/-</td>
          </tr>
          <tr>
            <td>6</td>
            <td colspan="2"><b>Fee/ Charges payable (in Rupees)</b></td>
                
          </tr>
          <tr>
            <td>A</td>
            <td>Payable to the RE (Annexure-A Sr.No. 26 of the KFS template-Part 1)	As per the case</td>
            <td>INR. {new Intl.NumberFormat('en-IN').format((Number(data?.customer[0]?.loan?.processing_fees) + (Number(data?.customer[0]?.loan?.processing_fees) * 0.18) + 2500).toFixed(2))}/-</td>
          </tr>
          <tr>
            <td>B</td>
            <td>Payable to third-party routed through RE (Annexure-A Sr.No. 26 of the KFS template – Part 1)</td>
            <td>INR. {new Intl.NumberFormat('en-IN').format((Number(data?.sanction_amount) * 0.06) +1180 +2360)}/-</td>
          </tr>
          <tr>
            <td>7</td>
            <td >Net disbursed amount (Deduction of Annexure-B Sr.No. 1 -  Annexure-B Sr.No. 6 ) (in Rupees)</td>
            <td>INR. {new Intl.NumberFormat('en-IN').format(Number(sanctionAmount) -(Number(feesWithTaxAndAdditionals) + Number(processingFeess))) }/-  </td>
          </tr>
          <tr>
            <td>8</td>
            <td>Total amount to be paid by the borrower (sum of 1 and 5) (in Rupees)</td>
            <td>INR. {new Intl.NumberFormat('en-IN').format(Math.round(emi) *data.tenure)}/-</td>
          </tr>
          <tr>
            <td>9</td>
            <td>Annual Percentage rate- Effective annualized interest rate (in percentage) (Annexure-A Sr.No. 12 of the KFS template-Part 1)</td>
            <td>Upto {data.interest_rate}% p.a.</td>
          </tr>
          <tr>
            <td>10</td>
            <td>Schedule of disbursement as per terms and conditions</td>
            <td>Refer illustrative Repayment Schedule</td>
          </tr>
          <tr>
            <td>11</td>
            <td>Due date of payment of instalment and interest</td>
            <td>
            {formattedEmiDate}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

            <br />
            {footerDiv(data.bank)}
          </div>
          {paymentDivs(emi_table)}
      </div>
    );
  }
}

 
