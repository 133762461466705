import React, { useState } from 'react'
import roleChecker from '../../../_helpers/rolechecker';
import { employeeService, misService } from '../../../_services';
import { Box, Button, Modal } from '@material-ui/core';
import LoanCloseModal from '../LoanCloseModal';
import LoanCancelModal from '../LoanCancelModel';
import LetterListModal from '../LetterListModal';
import { ApprovalRequestFilter } from '../../MakerCheckerFramework/ApprovalRequestFilter';
import { history } from '../../../_helpers';

export default function ActionModal(props) {
    const loan = props.loan
    const user = employeeService.userValue;
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    const style = {
        position: 'absolute',
        top: '41%',
        left: '85%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        height: 330,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        background: '#F8FAFF',
        borderRadius: '8px'
    };
    async function unlockLoan(loan_id, loan_account, loan_status) {
        await ApprovalRequestFilter.RequestFilter({
            service: 'misService',
            api: 'unlockLoan',
            id: loan_id,
            task: 'unlock Loan',
            updated_fields: ['loan_status'],
            sensitive_fields: [],
            new_values: { loan_status: "Disbursed" },
            old_values: { loan_status },
            identification_key: 'Loan Number',
            identification_value: loan_account,
            emp_id: user.emp_id,
            approval_needed: true,
            approved: false,
            approval_access: 'isAccountChecker',
            product: 'goldloans'
        })
            .then((x) => {
                console.log(x)
                alert("Unlock Request Sent to Concerned Authority")
                window.location.reload()
            })
            .catch((err) => {
                window.alert(err)
                console.log(err)
            })
    }
    return (
        <div style={{ display: "flex", justifyContent: 'flex-end' }}>
            <Button onClick={handleOpen}
                style={{ marginRight: '50px', background: '#DF2D2D', color: 'white', width: "100px" }}>Action</Button>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <div style={{ marginRight: '-30px' }}>
                        <h3 style={{ textAlign: 'center', marginBottom: '15px', fontWeight: "bold" }}>Action</h3>
                        {(roleChecker.isAccountChecker(user.role)) && (loan.loan_status !== 'Closed') && (
                            <LoanCloseModal
                                user={user}
                                loan={loan}
                                history={props.history}
                            />
                        )}
                        {(roleChecker.isGMLevelAccess(user.role) || roleChecker.isHOLevelAccess(user.role) || roleChecker.hasAccountsAccess(user.role)) && (loan.loan_status === 'Locked') && (
                            <Button
                                onClick={() => unlockLoan(loan._id, loan.loan_account, loan.loan_status)}
                                style={{ color: 'red', border: '2px solid #3760F1', width: '83%', marginBottom: '8px' }}
                                compact="true"
                            >Unlock&nbsp;Loan</Button>
                        )}
                        {(roleChecker.isAdminAccess(user.role) || roleChecker.isGMLevelAccess(user.role)) && (loan.loan_status !== 'Closed') && (loan.loan_status !== "Cancelled") && (loan.loan_status !== "Auctioned") && (
                            <LoanCancelModal
                                user={user}
                                loan={loan}
                                history={props.history}
                            />
                        )}
                        {/* {(roleChecker.isBranchLevelAccess(user.role)) && (loan.loan_status === 'Disbursed' || loan.loan_status === 'Suspense') && (<Button
                            style={{ width: '83%', color: "#3760F1", border: '2px solid #3760F1', marginBottom: '8px' }}
                            color="primary" compact="true" onClick={() => history.push(`/home/pledge?renew_of=${loan.loan_account}`)} >Renew Loan</Button>)} */}
                        {/* {(roleChecker.isBranchLevelAccess(user.role)) && (loan.loan_status === 'Disbursed' || loan.loan_status === 'Suspense') && (<Button color="primary" compact="true" onClick={() => props.history.push(`/home/pledge?renew_of=${loan.loan_account}`)} >Renew Loan</Button>)}
                    <LetterListModal
                        {...props}
                        user={user}
                        loan={loan}
                        history={props.history}
                    /> */}
                    </div>
                </Box>
            </Modal>
        </div>
    )
}
