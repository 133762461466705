import React from 'react'
import CerSaiUpload from '../../Applications/CerSaiUpload'
import { TabContext } from '../TabBar';
import { useContext } from "react";


const CersaiReport = () => {

  let TabContextValue = useContext(TabContext)
  let cust = TabContextValue.cust

  return (
    <div>
        <h2>CERSAI Report</h2>
        <CerSaiUpload application_id={cust.application_id} cust={cust} />
      </div>
  )
}

export default CersaiReport