import React, { useState, useEffect } from "react";
import { collectionService } from "../../_services/collection.service";
import { employeeService } from "../../_services";
import { Modal } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Box, Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";
import Reconciliation from "./Reconciliation";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { CSVLink } from "react-csv";
import { receiptService } from "../../_services/receipt.service";
import { Print } from "@material-ui/icons";
import datehelper from "../../_helpers/datehelper";
import roleChecker from "../../_helpers/rolechecker";
import { misService } from "../../_services";
import { loanService } from "../../_services";
import { Paper } from "@material-ui/core";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import DiscardedModel from "./DiscardedModel";
import { Input } from "antd";
import displayhelper from "../../_helpers/displayhelper";
import VAQRModel from "../Payments/VirtualAccounts/VAQRModel";
import ReceiptQRModel from "../Payments/VirtualAccounts/ReceiptQRModel";
import ReconcileWithAutoCloudModal from "./ReconcileWithAutoCloudModal"

const { Search } = Input;
function PaymentList(props) {
  const [payments, setPayments] = useState([]);
  const [product, setProduct] = useState('')
  const [loan, setLoan] = useState([])
  const [loanNumber, setLoanNumber] = useState('')
  const [amount, setAmount] = useState(null);
  const [open, setOpen] = useState(false)
  const [opens, setOpens] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false);
  const [value, setValue] = useState({})
  const [employeeList, setEmployeeList] = useState({});
  const [valu, setValu] = useState('')
  const [recon, setRecon] = useState(false)
  const [dateValue, setDateValue] = useState({})
  const [disable, setDisable] = useState(false)
  const [customerPayment, setcustomerPayment] = useState()
  const [receiptNo, setReceiptNo] = useState('')
  const [date, setDate] = useState()
  const [filterRecon, setFilterRecon] = useState("All")
  const [fpayments, setFPayments] = useState([]);
  const [disableButton, setDisableButton] = useState(false)
  const [remainingAmount,setRemaningAmount]=useState() 
  const [paymentMessage,setPaymentMessage]=useState(false)
  const user = employeeService.userValue;


  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setLoan([])
    setLoanNumber('')
  }

  const handleChange = (fieldKey, fieldValue) => {
    value[fieldKey] = fieldValue
    setValue(value)
    if (fieldKey[0] === 'loan_no') {
      let dates = new Date();
      setDate(dates)
      let year = dates.getFullYear();
      let month = dates.getMonth() + 1;
      let day = dates.getDate();
      let rand = Math.random() * 1000
      let randNum = Math.floor(rand);
      let receipt_no = `${day}${month}${year % 100}${fieldValue}${randNum}`
      setReceiptNo(receipt_no)
    }
    // console.log(receiptNo)
    // console.log(date)
  }

  async function getAllEmployees() {
    await employeeService.getAll().then((res) => {
      let list = {};
      // console.log(res)
      res.map((e) => {
        list[e.emp_id] = e.first_name + " " + e.last_name;
      });
      // console.log(list)
      setEmployeeList(list);
    });
  }

  useEffect(() => {
    getAllEmployees()
    
  }, [])
console.log("remainingAmount",remainingAmount)
  useEffect(() => {
    filterPayments(payments)
  }, [filterRecon])

  useEffect(()=>{
    receiptService.allEmployeeRemainingData({"emp_id":user.emp_id})
    .then(res=>{console.log("remainingAmount is",res);setRemaningAmount(res)})
    .catch(err=>console.log(err))
  },[user])

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    minHeight: 220,
    maxHeight: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: 'scroll',
    //paddingBottom: '30px',
  };

  function convert(dateStr) {
    let pDate = new Date(dateStr);
    return datehelper.getPaymentDateString(pDate);
  }

  const handleOpens = (payment) => {
    setOpens(true)
    setcustomerPayment(payment)
  }
  const handleCLoses = () => {
    setOpens(false)
    setcustomerPayment()
  }

  const styles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    height: 300,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    //overflow: 'scroll',
    //paddingBottom: '30px',
  };

  const handleClick = (e) => {
    e.preventDefault()
    props.history.push(`/home/printreceipt`, { receipt: { ...customerPayment, value: valu } })
  }



  const handleCreate = (e) => {
    e.preventDefault();

    if(amount>(remainingAmount?.threshold_amount-(remainingAmount?.remainingAmount || 0)
    )){
      setPaymentMessage(true)
      return;
    }
    if (window.confirm(`Do you really want to generate the receipt of ${Number(amount).toLocaleString("en-IN", { style: "currency", currency: "INR" })}`)) {
      setDisableButton(true)
      receiptService.Create({ ...value, date: date, payment: Number(amount), emp_name: user.first_name, receipt_no: receiptNo, mode: 'Cash' }).then((response) => {
        alert("Receipt Generated");
        setDisableButton(false)
        window.location.reload();
      })
        .catch((err) => {
          alert("An error occured")
          setDisableButton(false)
          console.log(err)
        })
    }

  }

  const handleDate = (fieldKey, fieldValue) => {
    dateValue[fieldKey] = fieldValue;
    setDateValue(dateValue)
  }

  const dateCompare = () => {
    const b = new Date()
    b.setTime(1654194600000)
    // b.setTime(1655317800000)
    // console.log(b)
    let c = b.getTime()
    return c
  }


  const handleSearch = (search) => {
    receiptService.getCashByAccount({ 'loan_account': search }).then((p) => {
      // console.log(p);
      setPayments(p);
      filterPayments(p);
    })
      .catch((err) => {
        console.log(err);
      })
  }


  const getByDate = () => {
    // console.log(dateValue);
    receiptService.getByDate(dateValue).then((response) => {
      // console.log(response);
      let x = filterReceipts(response);
      setPayments(x)
      filterPayments(x)
    })
      .catch((err) => {
        console.log(err);
      })
  }

  function filterReceipts(rl) {
    rl = rl.filter((y) => {
      if (!y.mode || y.mode === "Cash") {
        return true;
      }
      return false;
    })
    if (roleChecker.isCollectionTeam(user.role)) {
      rl = rl.filter((x) => x.emp_id === user.emp_id)
    }
    else if (roleChecker.isBranchLevelAccess(user.role)) {
      rl = rl.filter((x) => x.employees.length > 0 && x.employees[0].branch_id === user.branch_id);
    }
    return rl;
  }

  function filterPayments(pl) {
    var fpl = pl.filter((y) => {

      if (filterRecon === 'All') {
        return true;
      }
      if (filterRecon === "Done") {
        if (y.reconcilelist && y.reconcilelist.length > 0) {
          return true;
        }
        else {
          return false;
        }
      }

      if (filterRecon === "Pending") {
        if (y.reconcilelist && y.reconcilelist.length > 0) {
          return false;
        }
        else {
          return true;
        }
      }


    })
    setFPayments(fpl);
  }

  const searchLoan = () => {
    setLoan([])
    handleChange(['loan_no'], loanNumber)
    if (product === 'gold') {
      misService.getLoanByAccount(loanNumber)
        .then((data) => {
          if (data && data.length > 0) {
            if ((data[0].loan_status === "Closed" && !data[0].renewed_by)||(data[0].loan_status != "Disbursed" && data[0].loan_status!=="Closed")) {
              window.alert("Only Loan in collection mode allowed for payment collection");
              return;
            }
            handleChange(['cust_name'], data[0].name)
            setLoan(data)
          }
        })
        .catch((err) => {
          console.log(err, "Error")
        })
    } else if (product === 'sbl') {
      loanService.getBySearch({ application_id: loanNumber })
        .then((x) => {
          if (x && x.length > 0) {
            handleChange(['cust_name'], x[0].applicant.general_information.name)
            setLoan(x)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    } else if (product === '') {
      window.alert("Please, First select department")
    }
  }

  useEffect(() => {
    async function retrieveData() {
      await collectionService.fetchPayments().then((x) => {
        // console.log(x);
        x = filterReceipts(x);
        setPayments(x);
        filterPayments(x);
      }).
        catch((err) => {
          console.log(err);
        });
    }
    retrieveData();

  }, []);

  const headers = [
    { label: "Date", key: "date" },
    { label: "Receipt Number", key: "receipt_no" },
    { label: "Customer Name", key: "cust_name" },
    { label: "Loan Number", key: "loan_no" },
    { label: "Payment", key: "payment" },
    { label: "Employee", key: "emp_name" },
  ];


  const handlePayment = (paymentType) => {
    // if (paymentType == 'application_fee') {
    //   setValue({ ...value, comment: "Application fee" })
    //   setAmount(1200)
    //   setIsDisabled(true)
    // }
    if (paymentType == 'imd_charge') {
      setValue({ ...value, comment: "IMD charges" })
      setAmount(3800)
      setIsDisabled(true)
      checkRemainingAmount(3800)
    }
    if (paymentType == 'emi') {
      setValue({ ...value, comment: "" })
      setIsDisabled(false)
      setAmount('')

    }
    if (paymentType == 'for_closure') {
      setValue({ ...value, comment: "ForClosure Charge" })
      setIsDisabled(false)
      setAmount(2360)
      checkRemainingAmount(2360)
    }
    if (paymentType == 'soa_charge') {
      setValue({ ...value, comment: "SOA charge" })
      setIsDisabled(false)
      setAmount(590)
      checkRemainingAmount(590)
    }
    if (paymentType == 'lod_charge') {
      setValue({ ...value, comment: "LOD Charge" })
      setIsDisabled(false)
      setAmount(2360)
      checkRemainingAmount(2360)
    }
    if (paymentType == 'noc_charge') {
      setValue({ ...value, comment: "NOC Charge" })
      setIsDisabled(false)
      setAmount(590)
      checkRemainingAmount(590)
    }
    if (paymentType == 'other_charges') {
      setValue({ ...value, comment: "" })
      setIsDisabled(false)
      setAmount('')
    }
  }

const checkRemainingAmount=(val)=>{
  setPaymentMessage(false)

  if(val>(remainingAmount?.threshold_amount-(remainingAmount?.remainingAmount || 0)
  )){
    setPaymentMessage(true)
  }
}
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <Paper style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '10px', padding: '5px' }}>
              <select
                onChange={(e) => { setProduct(e.target.value); setLoan([]) }}
                value={product}
                style={{
                  width: '80%',
                  height: '30px',
                  borderRadius: '5px',
                  border: '1px solid grey',
                  color:"rgb(41,73,185)",
                  borderColor:"rgb(41,73,185)",
                }}>
                <option value='' disabled selected>Select the Department</option>
                <option value="gold" >Gold </option>
                <option value="sbl" >SBL</option>
              </select>
              <label
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  margin: '20px'
                }}
              >
                <input
                  type="text"
                  placeholder="Loan Number"
                  name="loanNumber"
                  onChange={(e) => setLoanNumber(e.target.value.trim().toUpperCase())}
                  value={loanNumber}
                  style={{
                    width: '180px',
                    height: '30px',
                    borderRadius: '5px',
                    border: '1px solid rgb(41,73,185)',
                    borderColor:"rgb(41,73,185)",
                  }} required />
                <button style={{ color: "white", marginLeft: '20px', height: '30px', backgroundColor: 'green', borderRadius: "5px", border: 'none', width: '120px', fontSize: 'medium' }} onClick={() => searchLoan()}  >Search</button>
              </label>
            </Paper>
            <hr style={{ width: '100%' }} />

            {loan && loan.length > 0 ?
              <Paper>
                <form style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', margin: '10px' }} onSubmit={(e) => handleCreate(e)}>
                  {/* <label
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    Customer Name
                    <input
                      type="text"
                      placeholder="Customer Name"
                      name="cust_name"
                      value={value.cust_name}
                      maxLength={25}
                      onChange={(e) => handleChange([e.target.name], e.target.value)}
                      style={{
                        width: '200px',
                        height: '30px',
                        borderRadius: '5px',
                        border: '1px solid grey'
                      }} disabled required />
                  </label> */}
                    <span style={{display:"flex", justifyContent:"left", color: "rgb(41,73,185)"}}>Name:-<p style={{color:"black"}} >{value.cust_name}</p></span>
                    <span style={{display:"flex", justifyContent:"right", color: "rgb(41,73,185)"}}>Loan No:-<p style={{color:"black"}}>{value.loan_no}</p></span><br/><br/>

                  {/* <label
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    Loan Number
                    <input
                      type="text"
                      placeholder="Loan Number"
                      name="loan_no"
                      value={value.loan_no}
                      pattern='[A-Z,0-9,a-z]{1,20}'
                      title="Loan Number can consist maximum 20 alpha numeric digits"
                      onChange={(e) => handleChange([e.target.name], e.target.value)}
                      style={{
                        width: '200px',
                        height: '30px',
                        borderRadius: '5px',
                        border: '1px solid grey'
                      }} disabled required />
                  </label> */}

                  <select
                    onChange={(e) => handlePayment(e.target.value)}
                    required
                    style={{
                      width: '80%',
                      height: '30px',
                      borderRadius: '5px',
                      border: '1px solid grey',
                      marginBottom: "20px",
                      color: "rgb(41,73,185)",
                      borderColor:"rgb(41,73,185)",
                    }}>
                    <option value='' disabled selected>Select Payment Type</option>
                    {/* <option value="application_fee" >Application Fee</option> */}
                    <option value="imd_charge" >IMD Charges </option>
                    <option value="for_closure" >ForClosure Charges </option>
                    <option value="soa_charge" >SOA Charges </option>
                    <option value="lod_charge" >LOD Charges </option>
                    <option value="noc_charge" >NOC Charges </option>
                    <option value="emi" >EMI</option>
                    <option value="other_charges" >Other Charges</option>
                  </select><br />


                  <label
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      color: "rgb(41,73,185)",
                    }}
                  >
                    Payment
                    <input
                      type="number"
                      placeholder="Payment"
                      name="payment"
                      // defaultValue={value.payment}
                      value={amount}
                      onChange={(e) => {setAmount(e.target.value);setPaymentMessage(false);
                      
                      if(e.target.value>(remainingAmount?.threshold_amount-(remainingAmount?.remainingAmount || 0)
                      )){
                        setPaymentMessage(true)
                      }}}
                      style={{
                        width: '200px',
                        height: '30px',
                        borderRadius: '5px',
                        border: '1px solid grey',
                        color:"black",
                        borderColor:"rgb(41,73,185)",
                      }}
                      required
                      disabled={isDisabled}
                    />
                  </label>
                  <label
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      color: "rgb(41,73,185)"
                    }}
                  >
                    Comment
                    <input
                      type="textarea"
                      placeholder="Comment"
                      rows='2'
                      name="comment"
                      defaultValue={value.comment}
                      onChange={(e) => handleChange([e.target.name], e.target.value)}
                      style={{
                        width: '200px',
                        height: '30px',
                        borderRadius: '5px',
                        border: '1px solid grey',
                        color:"black",
                        borderColor:"rgb(41,73,185)"
                      }} />
                  </label>
                  
 
                 {!paymentMessage &&  <button style={{ color: "white", margin: '20px', backgroundColor: '#0066cc', borderRadius: "5px", padding: "5px", border: 'none', width: '150px' }} disabled={disableButton ? "true" : ""}  >Submit</button>}
               
                </form>
                {paymentMessage && (
        <div>
        {remainingAmount && 
        <h6 style={{ color: "red" }}>
          *You  can   collect    upto Rs. {remainingAmount.threshold_amount-(remainingAmount?.remainingAmount-0) }
          </h6>}
          {remainingAmount && remainingAmount.remainingAmount!=0  && <h6 style={{ color: "red" }}>
            *Deposit amount {remainingAmount?.remainingAmount} Rs. to Kaabil Finance to generate a new receipt.
          </h6>}
          
          <div>
            {remainingAmount && remainingAmount.loans && remainingAmount.loans.length > 0 && (
              <table>
                <thead>
                  <tr>
                    <th>Loan Number</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {remainingAmount.loans.map((val, ind) => (
                    <tr key={ind}>
                      <td>{val.loan_no}</td>
                      <td>{val.payment}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      )}
              </Paper> :
              <span style={{ color: 'red', marginLeft: '100px' }}>**Loan number does not exist**</span>
            }
          </Typography>
          {
            console.log('payment add modal', loan)
          }
        </Box>
      </Modal>
      <Modal
        open={opens}
        onClose={handleCLoses}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <form onSubmit={(e) => handleClick(e)} >
              <label style={{
                display: 'flex',
                flexDirection: 'column',
                margin: '20px'
              }} > For
                <select style={{
                  width: '200px',
                  height: '30px',
                  borderRadius: '5px',
                  border: '1px solid grey'
                }} onChange={(e) => setValu(e.target.value)} required>
                  <option value=""></option>
                  <option value="EMI">EMI</option>
                  <option value="Loan Charges">Loan Charges</option>
                </select>
              </label>
              <button style={{ color: "white", margin: '20px', backgroundColor: '#0066cc', borderRadius: "5px", padding: "5px", border: 'none', width: '150px' }}  >Submit</button>
            </form>
          </Typography>
        </Box>
      </Modal>
      <h1>CASH Collected &nbsp;</h1>
      <Search
        onChange={(e) => handleSearch(e.target.value)}
        placeholder="Loan Account"
        enterButton
        style={{
          position: "sticky",
          top: "0",
          left: "0",
          width: "250px",
          marginTop: "2vh",
          marginRight: "20px",
        }}
      />
      {(roleChecker.hasReceiptCreateAccess(user.role)) && (
        <button style={{ marginLeft: "100px", border: "none", color: "white", backgroundColor: "red", borderRadius: "5px", padding: "6px", textStyle: "none" }} onClick={handleOpen} >Generate Cash Receipt</button>
      )}
      <br /><br />
      {roleChecker.hasAccountsAccess(user.role) && (
        <>
          <label>From Date: <input name="from_date" type="date" onChange={(e) => handleDate([e.target.name], e.target.value)} /></label>
          <label>To Date: <input name="to_date" type="date" onChange={(e) => handleDate([e.target.name], e.target.value)} /></label>
          <button style={{ marginLeft: "80px", border: "none", color: "black", backgroundColor: "orange", borderRadius: "5px", padding: "5px", textStyle: "none" }} onClick={() => getByDate()} >Load Payments</button>
          <FormControl style={{ marginLeft: "30px", width: "150px" }}>
            <InputLabel id="filter_recon_label">Filter Reconcilliation</InputLabel>
            <Select
              labelId="filter_recon_label"
              name="reconFilter"
              label="Filter Reconcilliation"
              value={filterRecon}
              onChange={(e) => setFilterRecon(e.target.value)}
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Done">Reconcialed</MenuItem>
              <MenuItem value="Pending">Pending Reconcial</MenuItem>

            </Select>
          </FormControl>

        </>
      )}

      <Table className="table table-striped" style={{ marginTop: '20px' }}>
        <Thead>
          <Tr>
            <Th style={{ width: "10%" }}>Date</Th>
            <Th style={{ width: "20%" }}>Receipt Number</Th>
            <Th style={{ width: "10%" }}>Customer</Th>
            <Th style={{ width: "10%" }}>Loan No.</Th>
            <Th style={{ width: "10%" }}>Payment</Th>
            <Th style={{ width: "10%" }}>Employee</Th>
            <Th style={{ width: "10%" }}>Comment</Th>
            <Th style={{ width: "15%" }}></Th>
            <Th style={{ width: "10%" }}>Print Slips</Th>
            <Th style={{ width: "15%" }}>Discard Payments</Th>
          </Tr>
        </Thead>
        {!fpayments && (<span className="spinner-border spinner-border-sm"></span>)}
        <Tbody>
          {fpayments &&
            fpayments.map((payment) => (
              <Tr key={payment._id}>
                {/* {dateCompare(payment.date)} */}
                <Td>{payment.date.split("T")[0]}</Td>
                <Td>{payment.receipt_no}</Td>
                <Td>{payment.cust_name}</Td>
                <Td>{payment.loan_no}</Td>
                <Td>{payment.payment && displayhelper.displayNumberAccounting(payment.payment)}</Td>
                <Td>{payment.emp_name}</Td>
                <Td>{payment.comment && <>{payment.comment}</>}</Td>
                {payment.is_discarded ? <Td colspan="3"><h6 style={{ marginLeft: "30px" }}>Discarded by - {employeeList[payment.discarded_by]} &nbsp; &nbsp; {payment.discarding_reason} </h6></Td> : payment.pending_discard ? <><Td colspan="3"><h6 style={{ marginLeft: "30px" }}>Discard Pending </h6></Td></> : <>
                  {(roleChecker.hasAccountsAccess(user.role)) ? (
                    <Td>{(payment.reconcilelist && payment.reconcilelist.length > 0) ? (<b>Reconciled<br />{payment.reconcilelist[0].loan_account}</b>) :
                      new Date(payment.date).getTime() >= dateCompare() && (payment && payment.loan_no && !payment.loan_no.includes("KFPLGL") ?<ReconcileWithAutoCloudModal txn_amt={payment.payment} txn_date={convert(payment.date)} payment_ref={payment.receipt_no} payment_mode={"cash"} reference_id={payment._id} application_id={payment.loan_no} /> : <Reconciliation loan_no={payment.loan_no} txn_date={convert(payment.date)} txn_amt={payment.payment} payment_mode={"cash"} reference_id={payment._id} payment_ref={payment.receipt_no} />)}</Td>
                  ) : <Td>{!payment.reconcilelist || payment.reconcilelist.length == 0 && (
                    <ReceiptQRModel receipt={payment}></ReceiptQRModel>
                  )} </Td>}

                  <Td>
                   <button style={{ color: "white", backgroundColor: 'green', borderRadius: "5px", padding: "5px", width: '125px', paddingTop: '8px', paddingBottom: '8px', border: 'none' }} onClick={() => handleOpens(payment)}> <Print fontSize="small" /></button>

                  </Td>
                  {/* <Td style={{ whiteSpace: "nowrap" }}>
                  <ProofModal payment={payment}></ProofModal>
                </Td> */}

                  {/* <Td style={{ whiteSpace: "nowrap" }}>
                  {
                    ((roleChecker.isGMLevelAccess(user.role)) && (
                      <button
                        style={{ width: "100%" }}
                        onClick={() => deletePayment(payment._id)}
                        className="btn btn-sm btn-danger"
                        disabled={payment.isDeleting}
                      >
                        {payment.isDeleting ? (
                          <span className="spinner-border spinner-border-sm"></span>
                        ) : (
                          <span>
                            <Delete />

                          </span>
                        )}
                      </button>
                    ))}
                </Td> */}
                  {payment.reconcilelist.length > 0 ? null :
                    <Td style={{ whiteSpace: "nowrap" }}>
                      {roleChecker.hasAccountsAccess(user.role) && (
                        <DiscardedModel payment={payment} />
                      )}
                    </Td>
                  }
                </>}
              </Tr>
            ))}
          {!payments && (
            <Tr>
              <Td colSpan="8" className="text-center">
                <span className="spinner-border spinner-border-lg align-center"></span>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </div>
  );
}

export { PaymentList };
