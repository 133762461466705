import React, { useState, useEffect } from 'react';
import './DocumentPendency.css';
import { Modal, Box, Typography, Select, MenuItem, Button, TextField } from "@material-ui/core";
import { employeeService, loanService } from '../../../../src/_services';
import { ApprovalRequestFilter } from "../../../../src/_components/MakerCheckerFramework/ApprovalRequestFilter";
import swal from 'sweetalert';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
const DocumentPendency = ({ cust }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedReceivedDocuments, setSelectedReceivedDocuments] = useState([]);
  const [bucketModalVisible, setBucketModalVisible] = useState(false);
  const [selectedBucket, setSelectedBucket] = useState('');
  const [docname, setDocname] = useState('');
  const [documents, setDocuments] = useState([]);
  const [whatPendingList, passPendingDocList] = useState([]);
  const [documentsFromCustomers, setDocumentsfromCustomers] = useState();
  
  const [receivedDocuments, setReceivedDocuments] = useState({
    "credit_bucket": [],
    "operation_bucket": [],
    "accounts_bucket": []
  });
  const [pendingDocuments, setPendingDocuments] = useState([]); // Added pendingDocuments state

  const user = employeeService.userValue;
  const emp_id = user.emp_id;
  const application_id = cust.application_id;

  // Fetch and set document list from API
  const listedDocumentCheckList = () => {
    loanService.getDocumentCheckList()
      .then(data => {
        setDocuments(data);
        console.log('call getDocumentCheckList response:', data);
        if (data && data.status === "success") {
          // Use data.data to set documents
        } else if (data && data.status === "error") {
          alert(data.message);
        }
      })
      .catch(error => {
        console.error('Error getting document checklist:', error);
        alert(error.message);
      });
  };

  useEffect(() => {
    listedDocumentCheckList();
  }, []);

  // Fetch and set document list from API
  const getDocumentCheckListfromCustomers = () => {
    loanService.getDocumentCheckListfromCustomers({ "application_id": application_id })
      .then(data => {
        console.log('call getDocumentCheckListfromCustomers:', data);
        if (data && data.status === "success") {
          // Use data.data to set documents
          setDocumentsfromCustomers(data.data.documentlist);
        } else if (data && data.status === "error") {
          alert(data.message);
        }
      })
      .catch(error => {
        console.error('Error getting document checklist:', error);
        alert(error.message);
      });
  };

  useEffect(() => {
    getDocumentCheckListfromCustomers();
  }, []);

  const filteredPending = documentsFromCustomers?.pending_list || [];
  const filteredReceived = documentsFromCustomers?.received_list || [];
  const creditApproval = documentsFromCustomers?.credit_approval;
  const opsApproval = documentsFromCustomers?.ops_approval;
  const accountApproval = documentsFromCustomers?.account_approval;
  console.log('documentsFromCustomers:::', filteredPending, filteredReceived);
  console.log('documentsFromCustomers:::', creditApproval, opsApproval,accountApproval);

  const filteredDocuments = documents.filter(doc =>
    doc.document_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handlePendingCheckboxChange = (docName) => {
    setSelectedReceivedDocuments(prevSelectedDocuments => {
      if (prevSelectedDocuments.includes(docName)) {
        return prevSelectedDocuments.filter(d => d !== docName);
      } else {
        return [...prevSelectedDocuments, docName];
      }
    });

    // setPendingDocuments(prevPendingDocuments => {
    //   filteredDocuments
    //               .filter(doc => doc.document_bucket_name === bucket &&
    //                 !filteredReceived.some(receivedItem => receivedItem.bucket_name === bucket &&
    //                   receivedItem.doc_list.includes(doc.document_name)))
    // });
  };

  const handleBucketSelect = (e) => {
    setSelectedBucket(e.target.value);
  };

  const handleDocnameChange = (e) => {
    setDocname(e.target.value);
  };

  const handleBucketModalSubmit = () => {
    const requestData = {
      docname: docname,
      selectedBucket: selectedBucket,
      emp_id: emp_id
    };

    loanService.saveDocumentCheckList(requestData)
      .then(data => {
        console.log('call saveDocumentCheckList response:', data);
        alert(data.message);
        if (data && data.status === "success") {
          setBucketModalVisible(false);
          // Refresh the document list after adding a new document
          listedDocumentCheckList();
        } else if (data && data.status === "error") {
          alert(data.message);
        }
      })
      .catch(error => {
        console.error('Error saving document checklist:', error);
        alert(error.message);
      });
  };

  const handleOpenBucketModal = () => {
    setBucketModalVisible(true);
  };

  const handleClose = () => {
    setBucketModalVisible(false);
  };

  const handleAddToReceived = (bucket) => {
    const requestData = {
      received_document: selectedReceivedDocuments,
      received_bucket: bucket,
      pending_document: selectedReceivedDocuments,
      emp_id: emp_id,
      application_id: application_id
    };
    loanService.addDocumentsToReceived(requestData)
      .then(data => {
        console.log('call addDocumentsToReceived response:', data);
        if (data && data.status === "success") {
          setReceivedDocuments(prevReceivedDocuments => ({
            ...prevReceivedDocuments,
            [bucket]: [...prevReceivedDocuments[bucket], ...selectedReceivedDocuments]
          }));
          window.location.reload();
          setSelectedReceivedDocuments([]);
        } else if (data && data.status === "error") {
          alert(data.message);
        }
      })
      .catch(error => {
        console.error('Error adding documents to received:', error);
        alert(error.message);
      });
  };
console.log("pendingDocuments::::",pendingDocuments)
const getApprovalDocumentCheckList = async (application_id, bucket) => {
  swal({
    title: "Alert",
    content: {
      element: "span",
      attributes: {
        innerHTML: "Are you sure? After this request approval, you won't be able to add documents to your bucket.",
        style: "color: red; font-weight: bold;"
      }
    },
    icon: "warning",
    buttons: true, // Ensures the "Okay" button is available
  }).then(async (willApprove) => {
    if (willApprove) {
      const documentPending = filteredDocuments
        .filter(doc => doc.document_bucket_name === bucket &&
          !filteredReceived.some(receivedItem => receivedItem.bucket_name === bucket &&
            receivedItem.doc_list.includes(doc.document_name)))
        .map(doc => doc.document_name)
        .join(', '); // Convert array to comma-separated string
           
      const documentReceived = filteredReceived
        .filter(item => item.bucket_name === bucket)
        .reduce((acc, item) => acc.concat(item.doc_list), [])
        .join(', '); // Convert array to comma-separated string

      console.log('documents that are pending', documentPending);
      console.log('documents that are selectedReceivedDocuments', documentReceived);
      const requestData={
        application_id: application_id,
        request_by: user.emp_id,
        verify_date: Date.now(),
        pending_list: documentPending,
        received_list: documentReceived,
        bucket: bucket,}
      try {
        const data = await ApprovalRequestFilter.RequestFilter({
          
          service: 'loanService',
          api: 'finalSaveDocumentCheckList',
          id: cust._id,
          task: 'Final Check For Documents CheckList',
          updated_fields: ["application_id", "request_by", "verify_date", "pending_list",'bucket', "received_list"],
          sensitive_fields: [],
          new_values: requestData,
          old_values: {},
          identification_key: 'Loan Number',
          identification_value: application_id,
          emp_id: user.emp_id,
          approval_needed: true,
          approved: false,
          approval_access: 'isGMLevelAccess',
          product: 'sbl',
        });

        if (data && data.status === "success") {
          alert(data.message);
        }
      } catch (error) {
        console.error('Error in saving insurance amount:', error);
        // Handle error here
      }
    }
  });
};
const handleWatsappMessage=(bucket)=>{

  const documentPending = filteredDocuments
        .filter(doc => doc.document_bucket_name === bucket &&
          !filteredReceived.some(receivedItem => receivedItem.bucket_name === bucket &&
            receivedItem.doc_list.includes(doc.document_name)))
        .map(doc => doc.document_name)
        .join(', '); // Convert array to comma-separated string

  loanService.docPendingWatsapp({application_id:cust.application_id,whatPendingList:documentPending})
  .then((res)=>{
   
   console.log("response for watsapp is",res)
window.alert(res.message)
  })
  .catch((err)=>console.log('err',err))
}

  const isFinalCheckSaveVisible = (bucket) => {
    if (bucket === 'credit_bucket') {
      return creditApproval;
    } else if (bucket === 'operation_bucket') {
      return opsApproval;
    } else if (bucket === 'accounts_bucket') {
      return accountApproval;
    }
    return false;
  };
  

  const styles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    height: 300,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <div className="top-row">
        {/* <button className="bucket-button" onClick={handleOpenBucketModal}>Add New Document in Check List</button> */}
      </div>

      <div className="container1">
        <div className="filter-column">
          <div className="heading">Filter:  <input
            type="text"
            placeholder="Search documents..."
            className="search-bar"
            value={searchTerm}
            onChange={handleSearchChange}
          /></div>
          <ul className="document-list">
            {filteredDocuments.map(doc => (
              <li key={doc._id}>
                <label style={{ textAlign: 'center' }}>{doc.document_name}</label>
              </li>
            ))}
          </ul>
        </div>
        {['credit_bucket', 'operation_bucket', 'accounts_bucket'].map((bucket) => (
          <div className="content-column" key={bucket}>
            <div className="heading">{bucket.replace('_', ' ').toUpperCase()}
            <div className="document-section-heading">Received Documents</div>
            </div>
            
            <div className="document-section">
          
              <ul className="document-list">
                {filteredReceived.filter(item => item.bucket_name === bucket).map((item, index) => (
                  item.doc_list.map((doc, docIndex) => (
                    <li key={`${index}-${docIndex}`} className="centertext">{doc}</li>
                  ))
                ))}
              </ul>
              <div className="row-buttons">
              {!isFinalCheckSaveVisible(bucket) && (
                <button onClick={() => getApprovalDocumentCheckList(application_id, bucket)} color='primary' variant='contained' style={{ marginLeft: '10px' }} className="action-button">Get Approval</button>)}

                {isFinalCheckSaveVisible(bucket) && (
          <h2 className="action-button">Your Request is Approved.</h2>
        )}
              </div>
              <div className="document-section-heading"><b>Pending Document</b></div>
            </div>
            <div className="document-section">
              <div className="document-list">
              <ul>
                {filteredDocuments
                  .filter(doc => doc.document_bucket_name === bucket &&
                    !filteredReceived.some(receivedItem => receivedItem.bucket_name === bucket &&
                      receivedItem.doc_list.includes(doc.document_name)))
                  .map(doc => (
                    
                    <li key={doc._id}>
                      <input
                        type="checkbox"
                        id={doc.document_name}
                        checked={selectedReceivedDocuments.includes(doc.document_name)}
                        onChange={() => handlePendingCheckboxChange(doc.document_name)}
                      />
                      <label htmlFor={doc.document_name}>{doc.document_name}</label>
                    </li>
                  )).length === 0 ? (
                    <li style={{ color: 'red' }}>Dear User, No pending documents for this Loan</li>
                  ) : (
                    filteredDocuments
                      .filter(doc => doc.document_bucket_name === bucket &&
                        !filteredReceived.some(receivedItem => receivedItem.bucket_name === bucket &&
                          receivedItem.doc_list.includes(doc.document_name)))
                      .map(doc => (
                        <li key={doc._id}>
                          <input
                            type="checkbox"
                            id={doc.document_name}
                            checked={selectedReceivedDocuments.includes(doc.document_name)}
                            onChange={() => handlePendingCheckboxChange(doc.document_name)}
                          />
                          <label htmlFor={doc.document_name}>{doc.document_name}</label>
                        </li>
                      ))
                  )}
              </ul>

              </div>
              <div>
              {!isFinalCheckSaveVisible(bucket) && (<>
                <button className="action-button" onClick={() => handleAddToReceived(bucket)}>Add to Received</button>
                <button className="action-button"onClick={()=>{if(window.confirm('Are you sure , you want to send a message for pending documents')){
                handleWatsappMessage(bucket)
                  }}} > <WhatsAppIcon/>  Send WhataApp To BM</button></>
                  )}
                {isFinalCheckSaveVisible(bucket) && (
                  <h2 className="action-button">Your Request is Approved.Now You Can't Add Documents</h2>
                )}
              </div>

            </div>
          </div>
        ))}
      </div>

      <Modal
        open={bucketModalVisible}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles}>
          <Typography id="modal-modal-title" variant="h6" component="h2" style={{ marginBottom: '20px' }}>
            Enter Document Name <span style={{ color: 'red' }}>*</span>
          </Typography>
          <form>
            <TextField
              style={{ width: '100%', marginBottom: '10px' }}
              placeholder="Enter Document Name"
              type="text"
              value={docname}
              onChange={handleDocnameChange}
            />
          </form>
          <Typography id="modal-modal-title" variant="h6" component="h2" style={{ marginBottom: '10px' }}>
            Select Bucket <span style={{ color: 'red' }}>*</span>
          </Typography>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedBucket}
            onChange={handleBucketSelect}
            style={{ width: '100%', marginBottom: '20px' }}
          >
            <MenuItem value="credit_bucket">Credit Bucket</MenuItem>
            <MenuItem value="operation_bucket">Operation Bucket</MenuItem>
            <MenuItem value="accounts_bucket">Accounts Bucket</MenuItem>
            <MenuItem value="other_bucket">Other Bucket</MenuItem>
          </Select>
          <div className="modal-actions">
            <Button onClick={handleBucketModalSubmit} variant="contained" color="primary" style={{ marginRight: '10px' }}>Save</Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default DocumentPendency;
