import React, { useState, useEffect } from 'react'
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import { Button, TextField } from "@material-ui/core";
import { uploadFileToBucket } from '../../../_helpers/upload';
import urlhelper from '../../../_helpers/urlhelper'
import { employeeService, loanService } from '../../../_services';
import datehelper from '../../../_helpers/datehelper';
import roleChecker from '../../../_helpers/rolechecker';
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "../../../css/common.css"



const CerSaiUpload = (props) => {

    const [open, setOpen] = useState(false);
    const [selectedFile, setSelectedFile] = React.useState(null);
    const [employeeList, setEmployeeList] = useState({});

    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false);

     const application_id = props.application_id
     const cust = props.cust

     const user = employeeService.userValue


    const onChangeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
      };

    const data = new FormData();
    data.append("file", selectedFile);


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        height: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        // overflow: 'scroll',
        // paddingBottom: '30px',
    };

    const bucket = process.env.REACT_APP_s3docsBucket

    const onClickHandler = async () => {
        const length = cust && cust.property_details && cust.property_details.cersai_reports && cust.property_details.cersai_reports.length +1
        const report = await uploadFileToBucket(bucket, "application/pdf", `cersai_reports/${application_id}_${length}_cersai.pdf`, selectedFile);
        console.log(report, "report here")
        loanService.addCersaiReport({application_id:application_id, document_url:report, requested_by:user.emp_id, requested_date:new Date()})
        .then((data)=>{
            console.log(data, "data here")
            window.location.reload()
        })
        .catch((err)=>console.log(err))
    }

    async function getAllEmployees() {
        await employeeService.getAll()
            .then((res) => {
                let list = {};
                res.map(e => {
                    list[e.emp_id] = e.first_name + ' ' + e.last_name
                })
                setEmployeeList(list)
            })
    }

    useEffect(() => {
      getAllEmployees();
    }, [])
    

    const statusUpdate = async (value) => {
        let obj
        if(value.status === "Approve"){
            obj={
                status:"approved",
                approved_by: user.emp_id,
                approverd_date: new Date(),
            }
        }else{
            obj={
                status:"rejected",
                rejected_by: user.emp_id,
                rejected_date: new Date(),
            }
        }
        const yes = window.confirm(`Are you sure you want to ${value.status.toUpperCase()} the report!!` )
        if(yes){
            loanService.updateCersaiStatus({...obj, application_id:application_id, _id:value._id})
        .then((data)=>{
            console.log(data)
            window.location.reload()
        })
        .catch((err)=>{
            console.log(err)
        })
        }
    }

    return (

        <div>
            <div style={{display:"flex", justifyContent: "flex-end"}}>
            <Button style={{ color:"white", backgroundColor:"blue", border:"1px solid"}} onClick={handleOpen}>Upload File</Button>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>

                    <Typography style={{ margin: "auto", }}>
                        <div className="offset-md-3 col-md-6">
                            <form method="post" action="#" id="#">
                                <div className="form-group files">
                                    <label>Upload Your File</label>
                                    <input
                                        accept=".pdf"
                                        type="file"
                                        className="form-control"
                                         onChange={(e) => onChangeHandler(e)}
                                    />
                                </div>
                                <button
                                    type="button"
                                    className="btn btn-success btn-block"
                                    onClick={() => onClickHandler()}
                                >
                                    Upload
                                </button>
                            </form>
                        </div>
                    </Typography>
                </Box>
            </Modal><br/>

            <div className='table-border'>
            <Table>
               <Thead>
                <Tr>
                    <Td className='table-row-th' style={{textAlign:"center"}}>Date</Td>
                    <Td className='table-row-th' style={{textAlign:"center"}}>Uploaded By</Td>
                    <Td className='table-row-th' style={{textAlign:"center"}}>Approved/Rejected By</Td>
                    <Td className='table-row-th' style={{textAlign:"center"}}>Approved/Rejected Date</Td>
                    <Td className='table-row-th' style={{textAlign:"center"}}>Status</Td>
                    <Td className='table-row-th' style={{textAlign:"center"}}>Action</Td>
                </Tr>
               </Thead>
               <Tbody>
                {cust && cust.property_details && cust.property_details.cersai_reports && cust.property_details.cersai_reports.map((e, idx)=>{
                    return <Tr key={e._id}>
                        <Td className='table-row-td' style={{textAlign:"center"}}>{e.requested_date && datehelper.displayDate(new Date(e.requested_date))}</Td>
                        <Td className='table-row-td' style={{textAlign:"center"}}>{e.requested_by && employeeList[e.requested_by]}</Td>
                        <Td className='table-row-td' style={{textAlign:"center"}}>{e.status === "approved" ? employeeList[e.approved_by] : employeeList[e.rejected_by]}</Td>
                        <Td className='table-row-td' style={{textAlign:"center"}}>{e.status === "approved" ? e.approved_date && datehelper.displayDateTime(new Date(e.approved_date)) : e.rejected_date && datehelper.displayDateTime(new Date(e.rejected_date))}</Td>
                        <Td className='table-row-td' style={{textAlign:"center"}}>{e.status && e.status === "approved"? <p style={{color:'green'}} ><b>Approved</b></p> : e.status === "rejected" ? <p style={{color:'red'}} ><b>Rejected</b></p> : <p style={{color:'orange'}} ><b>Approval<br/>Pending</b></p>}</Td>
                        <Td className='table-row-td' style={{textAlign:"center"}}>
                            {
                                 e.document_url && e.document_url !== "" && <a href={`/home/pdfviewer?pdf_url=`+urlhelper.encodeToBase64(e.document_url)+"&download=1"}>View Report</a>
                           }
                           {
                            roleChecker.isHOCreditApproverAccess(user.role) &&  (
                                e.status === "" || e.status === undefined ?  <div style={{display:'flex', flexDirection:"row", marginLeft:"30px"}}>
                                <Button style={{color:"green"}} onClick={()=>{statusUpdate({...e, status:'Approve'})}} >Approve</Button>
                                <Button style={{color:"red"}} onClick={()=>{statusUpdate({...e, status:'Reject'})}} >Reject</Button>
                            </div>: null
                            )
                           }
                        </Td>
                    </Tr> 
                })}
               </Tbody>
            </Table>
            </div>
        </div>
    )
}

export default CerSaiUpload