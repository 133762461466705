import React from 'react'
import Modal from "@material-ui/core/Modal";
import { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Fab from "@material-ui/core/Fab";
import SaveIcon from "@material-ui/icons/Save";
import { Paper } from '@material-ui/core';
import FormElement from '../uielements/FormElement';
import { loanService } from '../../_services';
import { ApprovalRequestFilter } from '../MakerCheckerFramework/ApprovalRequestFilter';
import { employeeService } from '../../_services';



const useStyles = makeStyles((theme) => ({
    modal: {
        width: "40%",
        overflow: "scroll",
        textAlign: "center",
        marginLeft: "auto",
        marginRight: "auto",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    table: {
        minWidth: 650,
    },
}));



const ColendingCloseModel = (props) => {

    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const [customer, setCustomer] = useState({})

    const application = props.application;

    const user = employeeService.userValue;

    const getcustomer = () => {
        loanService.getBySearch({application_id:application.application_id})
        .then((data)=>{
            setCustomer(data[0])
            console.log(data)
        })
        .catch((err)=>{
            console.log(err)
        })
    }

    const handleOpen = () => {
        setOpen(true);
        getcustomer()
    };

    const handleClose = () => {
        setOpen(false);
    };

    const initialValues = {
        co_lender_close_date: "",
        co_lender_close_amount: ""
    }

    const validationSchema = Yup.object().shape({
        co_lender_close_date: Yup.date().required("This is required"),
        co_lender_close_amount: Yup.number().required("This is required")
    })


    const onSubmit = async (fields) => {
        let params;
        let index;

       customer && customer.co_lending && customer.co_lending.map((x, idx)=>{
        if(x.co_lender_account_num === application.co_lender_data.co_lender_account_num){
             params = customer.co_lending;
            // let colender = params[idx]
            index=idx;
            params[idx]['co_lender_status']='ColenderLoanClosed'
            params[idx]={...params[idx], ...fields}
            // params.splice(idx,1)
            // params.push({...colender, ...fields})
        }
       })
       console.log(index)
       await ApprovalRequestFilter.RequestFilter({
        service: 'loanService',
        api: 'updateColender',
        id: customer._id,
        task: 'Close CoLender Status',
        updated_fields: [`co_lending[${index}].co_lender_name`, `co_lending[${index}].co_lender_close_date`, `co_lending[${index}].co_lender_close_amount` ],
        sensitive_fields: [],
        new_values: {co_lending:params},
        old_values: {},
        identification_key: 'Loan Number',
        identification_value: `co_lending[${index}].co_lender_account_num_${application.application_id}`,
        emp_id: user.emp_id,
        approval_needed: true,
        approved:false,
        approval_access:'isAccountChecker',
        product: 'sbl'
       })
       .then((x)=>{
        console.log(x)
        alert("Request Sent to concerned Authority")
        window.location.reload()
       })
       .catch((err)=>{
        window.alert(err)
        console.log(err)
       })
    //    loanService.update(customer._id,{co_lending:params})
    //    .then((x)=>{
    //     console.log(x)
    //     alert("Loan Closed")
    //     window.location.reload();
    //    })
    //    .catch((err)=>{
    //     console.log(err)
    //    })
    }
    
    return (
        <div>
            <Button onClick={handleOpen} style={{ color: "red", fontSize: "15px" }}>
                Close Loan
            </Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}>
                <Fade in={open}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={onSubmit}
                        >
                            {({ errors, touched, isSubmitting, setFieldValue }) => {
                                return (
                                    <Form>
                                        <div className={classes.paper}>
                                            <h2 style={{ marginTop: "10px", marginBottom:"20px" }}>CoLender Close Details</h2>
                                              {/* <div style={{marginTop:"20px", marginBottom: "20px"}}>
                                                <span style={{fontSize:"15px"}}>Colender Account Number: {application.co_lender_data.co_lender_account_num}</span> &nbsp; &nbsp;
                                                <span style={{fontSize:"15px"}}>Colender Name: {application.co_lender_data.co_lender_name}</span>
                                              </div>                                                 */}
                                            <div className="form-row" style={{marginTop:"10px"}}>
                                                <div className="form-group col">
                                                    <FormElement
                                                        label="Close Date"
                                                        name="co_lender_close_date"
                                                        type="date"
                                                        errors={errors}
                                                        touched={touched}
                                                    ></FormElement>
                                                    <FormElement
                                                        label="Close Amount"
                                                        name="co_lender_close_amount"
                                                        type="number"
                                                        errors={errors}
                                                        touched={touched}
                                                    ></FormElement>
                                                </div>
                                            </div>
                                            <div className="row justify-content-around">
                                                <Fab
                                                    type="submit"
                                                    disabled={isSubmitting}
                                                    color="primary"
                                                    aria-label="save"
                                                >
                                                    <SaveIcon />
                                                </Fab>
                                            </div>
                                        </div>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </Typography>
                </Fade>
            </Modal>
        </div>

    )
}

export default ColendingCloseModel