import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import { Button, TextField } from "@material-ui/core";
import { loanService ,customerService} from "../../../_services";
import Fab from "@material-ui/core/Fab";
import SaveIcon from "@material-ui/icons/Save";
import { Alert, AlertTitle } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));


const MoveToCollection = (props) => {
  const classes = useStyles();
  
    const application = props

  const [open, setOpen] = useState(false);
  const [fileNum, setFileNum] = useState(0)
  const [toast, setToast] = useState(false)

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    height: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    overflow: "scroll",
    paddingBottom: "30px",
  };

  const getAllCloudFile = () =>{
    if(props.application && props.application.loan && props.application.loan.allcloud_file_num){
      setFileNum(props.application.loan.allcloud_file_num)
    }
  }

  useEffect(() => {
    getAllCloudFile();
  }, [])
  
  console.log(props.application.application_id,"props?.eeeeeeeeeeeeeeeeeeeeeapplication_idprops?.application_id")
  const UpdateRpss = async () => {
    try {
      const data = await customerService.UpdateRps({ application_id: props?.application?.application_id, });
      console.log(data.status)
    } catch (err) {
      console.log(err);
    }
  };
  
  const changeStage = async (e) => {
    e.preventDefault();
    UpdateRpss()
    if(String(fileNum).length === 6){
      setToast(false)
    await loanService
      .movetoCollection({...application, file_num:fileNum, stage:"Collection" })
      .then((res) => {
        console.log(res);
        window.alert("Stage changed");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        window.alert("error changing stage");
      });
    }else{
      setToast(true)
      return
    }
  };


  
  
  return (
    <div>
      <Button color="primary" onClick={handleOpen} variant="outlined">
        Move to Collection
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography style={{ margin: "auto" }}>
            <form
              onSubmit={(e) => {
                changeStage(e);
              }}
            >
              <h4 style={{ textAlign: "center" }}>
                Move Loan to Collection Stage
              </h4>
              <br />
              <br />
              <h6>Enter the AutoCloud File Number</h6>
              <div
                className="my-3"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <TextField
                  type={"number"}
                  required
                  value={fileNum}
                  placeholder="AutoCloud File Number"
                  style={{ width: "100%" }}
                  onChange={(e)=>setFileNum(e.target.value)}
                />
              </div>
              <br />
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Fab type="submit" color="primary" aria-label="save">
                  <SaveIcon />
                </Fab>
              </div>
              {toast ? <div className={classes.root}>
        <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        <strong>Please Enter Correct File Number</strong>
      </Alert>
      </div>: null}
            </form>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default MoveToCollection;
