import React from "react";
import { Route, Switch } from "react-router-dom";
import { DashboardTab } from "./Dashboard";
import Collection from "./Collections/Collection";
import PledgeForm from "./GoldLoan/PledgeForm";
import PledgePrint from "./GoldLoan/PledgePrint";
import ApplicationPrint from "./GoldLoan/ApplicationPrint";
import LoanApplication from "./GoldLoan/LoanApplication";
import LoanList from "./GoldLoan/LoanList";
import SanctionPrint from "./SBL/SanctionLetter/SanctionPrint";
import AuthorityPrint from "./GoldLoan/AuthorityPrint";
import DocumentVerification from "./KYC/DocumentVerification";
import ReleasePrint from "./GoldLoan/ReleaseDoc/ReleasePrint";
import AuthorityLetterPrint from "./GoldLoan/AuthorityLetter/AuthorityLetterPrint";
import SBLSanction from "./SBL/SanctionLetter/SBLSanction";
import GoldSanctionPrint from "./GoldLoan/GoldSanctionPrint";
import GoldReminderPrint from "./GoldLoan/GoldReminderPrint";
import SubmissionPrint from "./GoldLoan/SubmissionDoc/SubmissionPrint";
import AuEnach from "./Payments/Enach/AuEnach";
import IciciEnach from "./Payments/IciciEnach/IciciEnach";
import { VirtualAccounts } from "./Payments/VirtualAccounts/VAIndex";
import { LetterTracking } from "./Legal/LetterTracking/Index";
import Letters from "./SBL/Letters/Index"
import LetterList from "./SBL/SblLettersList/Index"
import { SingleSms } from "./CustomerCare/sms/singlesms";
import BulkSms from "./CustomerCare/sms/bulksms";
import { PaymentLink } from "./CustomerCare/sms/paymentlink";
import NewGoldLoan from "./GoldLoan/NewGoldLoan";
import { Customers } from "./Customers/CustomerIndex";
import GoldIndex from "./GoldLoan/GoldIndex";
import { Reports } from "./Reports/ReportsIndex";
import { SBLIndex } from "./SBL/SBLIndex";
import Index from "./CustomerCare/MobileHistory/Index"
import { Receipts } from "./Collections/Receipts";
import { PrintReceipt } from "./Collections/PrintReceipt";
import { ColendingIndex } from "./CoLending/ColendingIndex";
import EnachCollectPayments from "./Payments/EnachCollect/EnachCollectPayments";
import { AccountingIndex } from "./Accounting/AccountingIndex";
import { GoldColendingList } from "./GoldLoan/GoldColendingList";
import PdfViewer from "./uielements/Viewer/PdfViewer";
import EmailNachCollectPayments from "./Payments/EmailNachCollect/EmailNachCollectPayments";
import { NachPaymentList } from "./Collections/NachPaymentList";
import GoldLetterIndex from "./GoldLoan/GoldLoanLetters/GoldLetterIndex";
import { BulkSubmissionDoc } from "./GoldLoan/SubmissionDoc/BulkSubmissionDoc";
import { CourtCaseIndex } from "./Legal/CourtCases/CourtCaseIndex";
import CallDetailsIndex from "./CustomerCare/VoiceSchedule/CallDetailsIndex"
import { BulkReleaseDoc } from "./GoldLoan/ReleaseDoc/BulkReleaseDoc";
import { ApprovalIndex } from "./MakerCheckerFramework/ApprovalIndex";
import roleChecker from "../_helpers/rolechecker";
import AccessDenied from "./AccessDenied";
import { DailyReportPrint } from "./GoldLoan/DailyReportPrint";
import FieldVisitList from "./Collections/FieldVisitList";
import SetAlert from './Collections/SetAlert'
import GoldPacketIndex from "./GoldLoan/GoldPacket/GoldPacketIndex";
import { CashManagementIndex } from "./GoldCashManagement/CashManagementIndex";
import { employeeService } from "../_services";
import Applicants from "./SBL/Applications/Applicants";
import { PaymentList } from "./Collections/PaymentList";
import { Users } from "./Users/Index";

import { TeamDashboardTab } from "./TeamDashboard";
import { RealTimeDashboardTab } from "./RealTimeDashboard";
import AssignTarget from './AssignTarget'
import ApprovalTarget from './ApprovalTarget'
import MonthlyTarget from "./MonthlyTarget";
import WeeklyTarget from "./WeeklyTarget";
import { RpsBulkUploade } from "./RpsBulkUploade";
import InsuranceBulkUpload from "./InsuranceBulkUpload";
import ReconciliationBulkUpload from "./ReconciliationBulkUpload";
import Dashboard2 from "./RealTimeDashboard2";
import {RealTimeCreditDashboard} from "./RealTimeCreditDashboard";

// import CicData from "./Cic/CicData";
import LegalCasesIndex from "./Legal/LegalCases/LegalCasesIndex";
import MapView from "./Collections/MapView";
import { DepositSlipDashboard } from "./Payments/DepositSlip/DepositSlipDashboard";
import DepositSlip from "./Payments/DepositSlip/DepositSlip";
import Errors from "./Errors";
import PettyCashIndex from "./PettyCash/PettyCashIndex";
import { BranchAchievements } from "./BranchAchievement";
import BulkUpload from "./Payments/bulkUpload/BulkUpload"
import AllCloudLedgerIndex from "./AllCloudLedger/AllCloudLedgerIndex" 
// import VendorData from "./SBL/Vendor/VendorData"
import { VendorIndex } from "./SBL/Vendor/VendorIndex";
import  BranchPettyCash  from "./SBL/BranchPettyCash/BranchPettyCash"
import BranchExpenses from "./SBL/BranchPettyCash/BranchExpenses"
import MyAllExpense from "./SBL/EmployeeExpenses/MyAllExpense"


export default function Routes(props) {
    const user = employeeService.userValue;
    const { path } = props.match;
    // console.log("path",props.match)
  
  return (
    <Switch>
            <Route exact path={path} component={DashboardTab} />
            { !["VENDOR"].includes(user.role) && 
              <>
            <Route path={`${path}/error`} component={Errors}/>
            <Route path={`${path}/collectionloans`} component={Collection} />
            <Route path={`${path}/applicants`} component={Applicants} />
            <Route path={`${path}/payments`} component={PaymentList} />
            <Route
              path={`${path}/verification`}
              component={DocumentVerification}
            />
            <Route exact path={`${path}/pledge`} component={PledgeForm} />
            <Route exact path={`${path}/release`} component={ReleasePrint} />
            <Route exact path={`${path}/goldloans/authorityletter`} component={AuthorityLetterPrint} />
            <Route exact path={`${path}/goldloans/colendinglist`} component={GoldColendingList} />
            <Route
              exact
              path={`${path}/submission`}
              component={SubmissionPrint}
            />
            <Route
              exact
              path={`${path}/bulksubmission`}
              component={BulkSubmissionDoc}
            />
            <Route
              exact
              path={`${path}/bulkrelease`}
              component={BulkReleaseDoc}
            />
            <Route
              exact
              path={`${path}/sbl/sanction`}
              component={SanctionPrint}
            />
            <Route
              exact
              path={`${path}/goldloans`}
              render={(props) => <LoanList {...props} />}
            />
            <Route path={`${path}/authority`} component={AuthorityPrint} />
            <Route path={`${path}/auenach`} component={AuEnach} />
            <Route path={`${path}/icicienach`} component={IciciEnach} />
            <Route path={`${path}/collectenach`} component={EnachCollectPayments} />
            <Route path={`${path}/collectmailnach`} component={EmailNachCollectPayments} />
            <Route path={`${path}/bulkuploadcollection`} component={BulkUpload} />
            <Route path={`${path}/depositslipdashboard`} component={DepositSlipDashboard}/>
            <Route path={`${path}/generatedepositslip`} component={DepositSlip}/>
            <Route path={`${path}/loans/print`} component={GoldSanctionPrint} />
            <Route
              path={`${path}/goldloans/print`}
              component={GoldSanctionPrint}
            />
            <Route
              path={`${path}/goldloans/reminderletter`}
              component={GoldReminderPrint}
            />

            <Route
              exact
              path={`${path}/application`}
              component={LoanApplication}
            />
            <Route
              exact
              path={`${path}/application/edit/:loanId`}
              component={LoanApplication}
            />
            <Route exact path={`${path}/sbl/:id`} component={SBLSanction} />
            <Route
              path={`${path}/application/print`}
              component={ApplicationPrint}
            />
            <Route path={`${path}/pledge/print`} component={PledgePrint} />
            <Route path={`${path}/goldloans/add`} component={NewGoldLoan} />
            <Route path={`${path}/users`} component={Users} />
            <Route path={`${path}/virtualaccounts`} component={VirtualAccounts} />
            <Route path={`${path}/goldloans`} component={GoldIndex} />
            <Route path={`${path}/goldloanletters`} component={GoldLetterIndex} />
            <Route path={`${path}/letters`} component={LetterTracking} />
            <Route path={`${path}/sblletters`} component={Letters} />
            <Route path={`${path}/letterlist`} component={LetterList} />
            <Route path={`${path}/singlesms`} component={SingleSms} />
            <Route path={`${path}/bulksms`} component={BulkSms} />
            <Route path={`${path}/paymentlink`} component={PaymentLink} />
            <Route path={`${path}/goldpacket`} component={GoldPacketIndex}/>
            {roleChecker.hasReportsAccess(user.role) && (
              <Route path={`${path}/reports`} component={Reports} />
            )}
            {!roleChecker .hasReportsAccess(user.role) && (
              <Route path={`${path}/reports`} component={AccessDenied} />
            )}
            <Route path={`${path}/allcloudledger`} component={AllCloudLedgerIndex} />

            <Route path={`${path}/pettycashlist`} component={PettyCashIndex}/>
            <Route path={`${path}/customers`} component={Customers} />
            <Route path={`${path}/sblloans`} component={SBLIndex} />
            <Route path={`${path}/colending`} component={ColendingIndex} />
            <Route path={`${path}/mobile`} component={Index} />
            <Route path={`${path}/paymentreceipts`} component={Receipts} />
            <Route path={`${path}/printreceipt`} component={PrintReceipt} />
            <Route path={`${path}/accounting`} component={AccountingIndex} />
            <Route path={`${path}/pdfviewer`} component={PdfViewer} />
            <Route path={`${path}/nachpayments`} component={NachPaymentList} />
            <Route path={`${path}/courtcases`} component={CourtCaseIndex} />
            <Route path={`${path}/calldetails`} component={CallDetailsIndex}/>
            <Route path={`${path}/approvals`} component={ApprovalIndex}/>
            <Route path={`${path}/accessdenied`} component={AccessDenied}/>
            <Route path={`${path}/dailyreport`} component={DailyReportPrint}/>
            <Route path={`${path}/fieldvisits`} component={FieldVisitList}/>
            <Route path={`${path}/setalert`} component={SetAlert}/>
            <Route path={`${path}/cashmanagement`} component={CashManagementIndex}/>

            <Route path={`${path}/teamdashboard`} component={TeamDashboardTab} />
            <Route path={`${path}/realtimedashboard`} component={RealTimeDashboardTab} />
            <Route path={`${path}/assignTarget`} component={AssignTarget} />
            <Route path={`${path}/monthlyTarget`} component={MonthlyTarget} />
            <Route path={`${path}/approvalTarget`} component={ApprovalTarget} />
            <Route path={`${path}/weeklyTarget`} component={WeeklyTarget} />
            <Route path={`${path}/dashboard`} component={Dashboard2} />
            <Route path={`${path}/rpsbulkuploade`} component={RpsBulkUploade} />   
            
            <Route path={`${path}/insurancebulkuploade`} component={InsuranceBulkUpload} />
            <Route path={`${path}/reconciliationBulkUpload`} component={ReconciliationBulkUpload} />           
            <Route path={`${path}/creditDashboard`} component={RealTimeCreditDashboard} />
          
            <Route path={`${path}/branchachievements`} component={BranchAchievements} />
            <Route path={`${path}/legalcases`} component={LegalCasesIndex}/>
            <Route path={`${path}/branchexpense`} component={BranchExpenses}/>
            <Route path={`${path}/employexpense`} component={MyAllExpense}/>

            <Route path={`${path}/mapview`} component={MapView} />
            </>
            }
            <Route path={`${path}/vendorsdata`} component={VendorIndex} />
            {/* <Route path={`${path}/cicdata`} component={CicData} /> */}
          </Switch>
  );
}
