import React from 'react'
import { useReactToPrint } from "react-to-print";


const DocumentListLetter = (props) => {

    const DocumentListLetterRef = React.useRef();

    const handleSubmissionPrint = useReactToPrint({
        content: () => DocumentListLetterRef.current,
        pageStyle: "print"
    });


    const data = props.location.state;
    console.log(data)

    let date = new Date();
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct",
        "Nov", "Dec"];
    const yy = date.getFullYear();
    const mm = date.getMonth();
    const dd = date.getDate();

    date = months[mm] + " " + dd + "," + " " + yy;

    return (
        <>

            <div style={{ textAlign: "center" }}>
                <button onClick={handleSubmissionPrint}>Print Letter</button>
            </div>

            <div ref={DocumentListLetterRef} style={{ marginTop: "250px", fontSize: "20px", textAlign: "justify", marginLeft: "53px" }}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <span>Date: {date}</span>
                    <span>Letter Id: {data.letter_id}</span>
                </div><br />

                <div>
                    <b>{data.title}. {data.name}</b><br />
                    <b>{data.relation_type} {data.relation}</b><br />
                    {data.address}<br />
                </div><br /><br />

                <div>
                    Dear Sir/Madam<br />
                    Reg: List of Documents
                </div>

                <div>
                    With reference to the above, we here by confirm that <b>{data.name} {data.relation_type} {data.relation}</b> has
                    availed secured business loan (SBL) of Rs. {data.amount} ({data.inwords} ) under loan
                    Account no: {data.application_id} From Kaabil Finance Pvt. Ltd. against the security of registered /

                    Equitable mortgage of the following property the list of documents submitted for securing
                    the Loan as under.
                </div><br />

                <div>
                    <table style={{ width: "60%", border: "1px solid black", margin: "auto" }}>
                        <tbody>
                            <tr>
                                <td style={{ border: "1px solid black" }}>
                                    Documents Type
                                </td>
                                <td style={{ border: "1px solid black" }}>
                                    Original/PhotoCopy
                                </td>
                            </tr>
                            {data.documents && data.documents.map((x) => {
                                return <tr>
                                    <td style={{ border: "1px solid black" }}>
                                        {x.document_type}
                                    </td>
                                    <td style={{ border: "1px solid black" }}>
                                        {x.original_type}
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div><br />

                <div><br />
                </div><br />

                <div>
                    Thanks & Regards
                    Kaabil Finance Pvt. Ltd.
                </div><br /><br />

                <div>
                    Authorised signatory
                </div>
            </div>
        </>
    )
}

export default DocumentListLetter